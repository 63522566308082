import React, { useState } from "react";
import { useEffect } from "react";
import Footer from "../../components/ko/Footer";
import Header from "../../components/ko/Header";
import ContactModal from "../../components/ko/ContactModal";
import { Link } from "react-router-dom";
import { get_boardList } from "../../utils/axios";

import NewsDialogB from "../../components/NewsDialogB";

const News = () => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [NewsData, setNewsData] = useState([]);
  const [isOpenDialogB, setIsOpenDialogB] = useState(false);
  const [isOpenContact, setIsOpenContact] = useState(false);

  useEffect(() => {
    (async () => {
      const params = {
        page: page,
        size: 10,
        boardIdx: 1,
      };
      try {
        const { data } = await get_boardList(params);
        setTotalPage(data.totalPages);
        setNewsData(data.List.content);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [page]);

  const openDialog = (articleIdx: number, externalUrl: string) => {
    switch (articleIdx) {
      case 11:
        setIsOpenDialogB(true);
        break;
      default:
        window.open(externalUrl);
        break;
    }
  };

  const pagination = () => {
    const result = [];
    for (let i = 1; i <= totalPage; i++) {
      result.push(
        <li
          key={i}
          className="page-item"
          onClick={() => setPage(i)}
          style={{ cursor: "pointer" }}
        >
          {page === i ? (
            <span className="page-link" style={{ fontWeight: "bold" }}>
              {i}
            </span>
          ) : (
            <span className="page-link" style={{ color: "gray" }}>
              {i}
            </span>
          )}
        </li>
      );
    }
    return result;
  };
  const onclickModal = () =>{
    setIsOpenContact(true)
  }

  return (
    <div className="wrap">
      <Header />
      <div id="content">
        <section id="newsMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">
                  PR Center · News
                </p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">
                  METAROCK NEWS
                </h3>
              </div>
            </div>
          </div>
        </section>

        <section id="newsCont">
          <div className="container">
            {NewsData?.map(
              ({
                thumbnailUrl,
                externalAuthor,
                articleSubject,
                articleContent,
                regDt,
                externalUrl,
                articleIdx,
              }) => (
                <div
                  key={articleIdx}
                  onClick={() => {
                    openDialog(articleIdx, externalUrl);
                  }}
                  className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft"
                  data-wow-delay="0.6s"
                >
                  <div className="box">
                    <a title="디지털뉴스" target="_blank" rel="noreferrer">
                      <div className="thumb">
                        <img src={thumbnailUrl} alt={articleSubject} />
                      </div>
                      <div className="cont">
                        <p className="sub">
                          {externalAuthor}. {regDt}
                        </p>
                        <dl>
                          <dt>{articleSubject}</dt>
                          <dd>{articleContent}</dd>
                        </dl>
                      </div>
                    </a>
                  </div>
                </div>
              )
            )}
          </div>
          <ul
            className="pagination pagination_wrap"
            style={{ cursor: "pointer" }}
          >
            {pagination()}
          </ul>

          {isOpenDialogB ? (
            <NewsDialogB setIsOpenDialogB={setIsOpenDialogB} />
          ) : null}
   
        </section>

        <section id="newsContact" className="sub_contact">
          <div className="container">
            <div className="row">
              <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                메타록에 대해 문의할 사항이 있으신가요?
              </h4>
              {/* <button
    title="contact" 
    className="btn_contact wow fadeInUp"
    data-wow-delay="0.8s"
    onClick={() => {
      onclickModal()
    }}
  >
    CONTACT US
      </button> */}
              <Link to={"/contact"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
            </div>
          </div>
        </section>
      </div>
      {isOpenContact ? (
              <ContactModal setIsOpenContact={setIsOpenContact} />
            ) : null}
      <footer>
        <div className="container">
          <Footer />
        </div>
      </footer>
    </div>
  );
};

export default News;

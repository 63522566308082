import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import moment from "moment";
import { get_boardList } from "../../utils/axios";
import ContactModal from "./ContactModal";



const Main = () => {
  const [newsData, setNewsData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [isOpenContact, setIsOpenContact] = useState(false);
  

  const onclickModal = () =>{
    setIsOpenContact(true)
  }

  useEffect(() => {
    (async () => {
      const params = {
        page: page,
        size: 10,
        boardIdx: 1,
      };
      try {
        const { data } = await get_boardList(params);
        setTotalPage(data.totalPages);
        setNewsData(data.List.content);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [page]);

  const openDialog = (articleIdx: number, externalUrl: string) => {
    switch (articleIdx) {
      default:
        window.open(externalUrl);
        break;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Header />

      <div id="popup_layer" className="container"></div>
      <div className="wrap">
        <div id="content">
          <section id="main">
            <div className="container">
              <video title="matavideo" muted autoPlay loop>
                <source
                  src={process.env.PUBLIC_URL + "video/mainvideo.mp4"}
                  type="video/mp4"/>
              </video>
              <div className="row">
                <div className="col-12">
                  <h3 className="wow fadeInDown" data-wow-delay="0.4s">
                    The Way to the Metaverse. <br />
                    Meet us, Metarock
                  </h3>
                  <p className="wow fadeInDown" data-wow-delay="0.8s">
                    METAROCK은 메타버스를 지향하고
                    <br />
                    E-스포츠를 중심으로 한 플랫폼 사업을 진행합니다.
                  </p>
                  {/* <button title="contact" className="btn_contact wow fadeInUp" data-wow-delay="0.8s" onClick={()=> {
                    onclickModal()
                    }}
                    >
                    CONTACT US
                  </button> */}
                  <Link to={"/contact"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
                </div>
              </div>
            </div>
          </section>

          <section id="service">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p className="title wow fadeInDown" data-wow-delay="0.2s">
                    Our Services
                  </p>
                  <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                    METAROCK 서비스 모델
                  </h4>
                </div>
              </div>
              <div className="row fir">
                <div
                  className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="images/service/service1.png"
                    alt="2WinChance, 세계최고의 “컨테스트 베팅” 플랫폼"
                  />
                </div>
                <div
                  className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft"
                  data-wow-delay="0.7s"
                >
                  <dl>
                    <dt>2WinChance, 국내 최초 합법 판타지스포츠게임 중심의 종합게임 플랫폼</dt>
                    <dd>
                    2WinChance는 국내 최초로 게임물관리위원회의 승인을받은 판타지스포츠 게임인 WINNERPICK을 중심으로 스포츠승부예측 게임, 보드게임 등 다양한 장르의 P2E 게임을 만나볼 수 있는 메타록의 종합게임플랫폼입니다.

                    </dd>
                    <dd>
                      <Link to={"/game"} title="투윈챈스" className="btn_more">
                        더 알아보기
                      </Link>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="row cross sec">
                <div
                  className="col-lg-6 col-md-12 col-sm-12 right wow fadeInRight"
                  data-wow-delay="0.8s"
                >
                  <img
                    src="images/test/service2.png"
                    alt="메타버스 관련 비즈니스"
                  />
                </div>
                <div
                  className="col-lg-6 col-md-12 col-sm-12 wow fadeInRight"
                  data-wow-delay="0.9s"
                >
                  <dl>
                    <dt>세계최초 탈 중앙 메타버스, DotCube Protocol(DCP)</dt>
                    <dd>
                    메타록에서 자체 개발한 새로운 인터넷 DotCube Protocol를 활용하여 세상 모든 유저는 무료로 자신만의 메타버스를 만들 수 있으며, 메타버스를 누구나 쉽게 만들 수 있도록 저작툴 또한 무료로 제공해드립니다.

                    </dd>
                    <dd>
                      {" "}
                      <Link
                        to={"/metaverse"}
                        title="메타버스"
                        className="btn_more"
                      >
                        더 알아보기
                      </Link>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="row thi">
                <div
                  className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft"
                  data-wow-delay="1.0s"
                >
                  <img
                    src="images/service/service3.png"
                    alt="Heaven, 차세대 서바이벌 배틀 게임의 표준"
                  />
                </div>
                <div
                  className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft"
                  data-wow-delay="1.1s"
                >
                  <dl>
                    <dt>Heaven, 차세대 서바이벌 배틀 게임의 표준</dt>
                    <dd>
                      Heaven은 DOTA/CHAOS 등 글로벌 인기 게임을 모티브로 탄생한
                      Aeon Of Strife(AOS) 게임입니다.
                    </dd>
                    <dd>
                      {" "}
                      <Link to={"/heaven"} title="헤븐" className="btn_more">
                        더 알아보기
                      </Link>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="row cross for">
                <div
                  className="col-lg-6 col-md-12 col-sm-12 right wow fadeInRight"
                  data-wow-delay="1.2s"
                >
                  <img
                    src="images/service/service4.png"
                    alt="Mozza & Sali Full CG 3D Animation"
                  />
                </div>
                <div
                  className="col-lg-6 col-md-12 col-sm-12 wow fadeInRight"
                  data-wow-delay="1.3s"
                >
                  <dl>
                    <dt>Mozza &amp; Sali Full CG 3D Animation</dt>
                    <dd>
                      Adulkid부터 Kidult까지 함께 공감하고 즐길 수 있는 슬랩스틱
                      코미디로 멀티플랫폼 환경의 트랜스미디어 콘텐츠 제작됩니다.
                    </dd>
                    <dd>
                      {" "}
                      <Link
                        to={"/mozza"}
                        title="모짜와살리"
                        className="btn_more"
                      >
                        더 알아보기
                      </Link>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </section>

          <section id="mainNews">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p className="title wow fadeInDown" data-wow-delay="0.2s">
                    Our News
                  </p>
                  <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                    METAROCK NEWS
                  </h4>
                </div>
                <div className="row">
                  {newsData.map(
                    (
                      {
                        thumbnailUrl,
                        articleSubject,
                        externalAuthor,
                        regDt,
                        articleContent,
                        externalUrl,
                        articleIdx,
                      },
                      i
                    ) => {
                      return (
                        <div
                          key={i}
                          className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft"
                          data-wow-delay="0.65s"
                          onClick={() => {
                            openDialog(articleIdx, externalUrl);
                          }}
                        >
                          {i < 6 ? (
                            <a target="_blank" rel="noreferrer">
                              <div className="box">
                                <div className="thumb">
                                  <img
                                    src={thumbnailUrl}
                                    alt={articleSubject}
                                  />
                                </div>
                                <div className="cont">
                                  <p className="sub">
                                    {externalAuthor}. {regDt}
                                  </p>
                                  <dl>
                                    <dt>{articleSubject}</dt>
                                    <dd> {articleContent}</dd>
                                  </dl>
                                </div>
                              </div>
                            </a>
                          ) : null}
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="col-12 wow fadeInUp" data-wow-delay="0.9s">
                  <Link to={"/news"} className="btn_more">
                    더 보기<i className="fal fa-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section id="contact">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                    메타록에 문의사항이 있으신가요?
                  </h4>
                  {/* <button title="contact" className="btn_contact wow fadeInUp" data-wow-delay="0.8s" onClick={()=> {
                    onclickModal()
                    }}
                    >
                    CONTACT US
                  </button> */}
                  <Link to={"/contact"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
                </div>
              </div>
            </div>


   
          </section>
        </div>
        {isOpenContact ? (
              <ContactModal setIsOpenContact={setIsOpenContact} />
        ) : null}

        <footer>
          <div className="container">
            <div className="row">
              <Footer />
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Main;

import React, { useEffect, useState } from "react";
import Layout from "../../components/en/Layout";
import { get_boardList } from "../../utils/axios";
import { BoardListContent } from "../../utils/types";
import { Link } from "react-router-dom";

import NewsDialogB from "../../components/NewsDialogB";
import ContactModalEn from "../../components/en/ContactModalEn";

const NewsEn: React.FC = () => {
  const [isOpenContactEn, setIsOpenContactEn] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [NewsData, setNewsData] = useState<BoardListContent[] | []>([]);
  const [isOpenDialogB, setIsOpenDialogB] = useState(false);
  const onclickModalEn = () => {
    setIsOpenContactEn(true);
  };
  useEffect(() => {
    (async () => {
      const params = {
        page: page,
        size: 10,
        boardIdx: 1,
      };
      try {
        const { data } = await get_boardList(params);
        setTotalPage(data.totalPages);
        setNewsData(data.List.content);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [page]);

  const openDialog = (articleIdx: number, externalUrl: string) => {
    switch (articleIdx) {
      case 11:
        setIsOpenDialogB(true);
        break;
      default:
        window.open(externalUrl);
        break;
    }
  };

  const pagination = () => {
    const result = [];
    for (let i = 1; i <= totalPage; i++) {
      result.push(
        <li
          key={i}
          className="page-item"
          onClick={() => setPage(i)}
          style={{ cursor: "pointer" }}
        >
          {page === i ? (
            <span className="page-link" style={{ fontWeight: "bold" }}>
              {i}
            </span>
          ) : (
            <span className="page-link" style={{ color: "gray" }}>
              {i}
            </span>
          )}
        </li>
      );

    }
    return result;
  };
  

  return (
    <Layout>
      <div id="content">
        <section id="newsMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">
                  PR Center · News
                </p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">
                  METAROCK NEWS
                </h3>
              </div>
            </div>
          </div>
        </section>

        <section id="newsCont">
          <div className="container">
            {NewsData?.map((data) => (
              <div
                key={data.articleIdx}
                className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft"
                data-wow-delay="0.8s"
              >
                <div className="box">
                  <a href={data.externalUrl} target="_blank" rel="noreferrer">
                    <div className="thumb">
                      <img src={data.thumbnailUrl} alt={data.articleSubject} />
                    </div>
                    <div className="cont">
                      <p className="sub">
                        {data.externalAuthor}. {data.regDt}
                      </p>
                      <dl>
                        <dt>{data.articleSubject}</dt>
                        <dd>{data.articleContent}</dd>
                      </dl>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>
          <ul className="pagination pagination_wrap">{pagination()}</ul>
          {/* {isOpenDialogA ? (
            <NewsDialogA setIsOpenDialogA={setIsOpenDialogA} />
          ) : null} */}
          {isOpenDialogB ? (
            <NewsDialogB setIsOpenDialogB={setIsOpenDialogB} />
          ) : null}
        </section>

        <section id="newsContact" className="sub_contact">
          <div className="container">
            <div className="row">
              <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                Do you have any questions about METAROCK?
              </h4>
              {/* <button
                    title="contact"
                    className="btn_contact wow fadeInUp"
                    data-wow-delay="0.8s"
                    onClick={() => {
                    onclickModalEn();
                    }}
                    >
                    CONTACT US
                  </button> */}
                 <Link to={"/contact_en"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
            </div>
          </div>
        </section>
      </div>
      {isOpenContactEn ? (
              <ContactModalEn setIsOpenContactEn={setIsOpenContactEn} />
            ) : null}
    </Layout>
  );
};

export default NewsEn;

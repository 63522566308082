import React, { useEffect,useState } from "react";
import Layout from "../../components/en/Layout";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import $ from "jquery";
import ContactModalEn from "../../components/en/ContactModalEn";

const GameEn: React.FC = () => {
  const [isOpenContactEn, setIsOpenContactEn] = useState(false);
  const onclickModalEn = () => {
    setIsOpenContactEn(true);
  };
  useEffect(() => {
    $("#game .fir .col-lg-4 li").click(function (this, e) {
      e.preventDefault();
      $(this).addClass("on").siblings().removeClass("on");

      if ($("#game .fir .col-lg-4 li:eq(0)").hasClass("on")) {
        $(this)
          .parents(".col-lg-4")
          .siblings(".col-lg-8")
          .children("img")
          .attr("src", "images/test/game1.jpg");
        $(this)
          .parents(".col-lg-4")
          .siblings(".col-lg-8")
          .children("img")
          .attr("alt", "Esports Betting Game 서비스");
      } else if ($("#game .fir .col-lg-4 li:eq(1)").hasClass("on")) {
        $(this)
          .parents(".col-lg-4")
          .siblings(".col-lg-8")
          .children("img")
          .attr("src", "images/test/game2.jpg");
        $(this)
          .parents(".col-lg-4")
          .siblings(".col-lg-8")
          .children("img")
          .attr("alt", "Pro Esports 구단 전적 관리 시스템 구축");
      } else {
        $(this)
          .parents(".col-lg-4")
          .siblings(".col-lg-8")
          .children("img")
          .attr("src", "images/game/game3.png");
        $(this)
          .parents(".col-lg-4")
          .siblings(".col-lg-8")
          .children("img")
          .attr("alt", "전문 Esports News Journal 서비스");
      }
    });
  }, []);
  SwiperCore.use([Autoplay]);
  return (
    <div>
      <Layout>
        <div id="content">
          <section id="gameMain" className="sub_main">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p className="title wow fadeInDown" data-wow-delay="0.2s">
                    BUSINESS · 2WinChance
                  </p>
                  <h3 className="wow fadeInDown" data-wow-delay="0.4s">
                    2WinChance,
                    <br />
                    the world's first "Esports" strategy battle platform
                  </h3>
                  <p className="text wow fadeInDown" data-wow-delay="0.6s">
                    2WinChance is the world's first Esports strategy battle game
                    and is already attracting attention not only in Korea but
                    also in various countries.
                  </p>
                </div>
              </div>
              <Swiper
                autoplay={{ delay: 4000, disableOnInteraction: false }}
                modules={[EffectFade]}
                effect="fade"
                allowTouchMove={false}
                speed={2000}
                className="swiper mySwiper swiper-container"
              >
                <SwiperSlide className="swiper-slide"></SwiperSlide>
                <SwiperSlide className="swiper-slide"></SwiperSlide>
                <SwiperSlide className="swiper-slide"></SwiperSlide>
              </Swiper>
            </div>
          </section>

          <section id="game" className="sub_cont">
            <div className="container">
              <div className="row fir">
                <div className="col-12">
                  <p className="title wow fadeInDown" data-wow-delay="0.2s">
                    New opportunities and growth of esports
                  </p>
                  <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                    2WinChance Platform
                  </h4>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <ul>
                    <li className="on wow fadeInLeft" data-wow-delay="0.6s">
                      <a title="contact">
                        <dl>
                          <dt>
                            Esports <br className="pc" />
                            Strategy Battle game
                          </dt>
                          <dd>
                            Hire top professional gamers who belong to different
                            teams to form a legendary team. Players from all
                            over the world compete in real-time with All-Star
                            teams to determine the winner.
                          </dd>
                        </dl>
                      </a>
                    </li>
                    <li className="wow fadeInLeft" data-wow-delay="0.7s">
                      <a title="contact">
                        <dl>
                          <dt>
                            The World's First Meta <br className="pc" />
                            Game Genre
                          </dt>
                          <dd>
                            The trend of mainstream play tendencies(meta)
                            changes rapidly, which will have the most impact on
                            the Esports game. addition to selecting players,
                            brain fights with formation / management will
                            present joy that has never been experienced before.
                          </dd>
                        </dl>
                      </a>
                    </li>
                    <li className="wow fadeInLeft" data-wow-delay="0.8s">
                      <a title="contact">
                        <dl>
                          <dt>
                            Flexible expansion of <br className="pc" />
                            business models
                          </dt>
                          <dd>
                            Flexible customization in accordance with
                            international regulations allows various business
                            models. We can provide a separate export version for
                            other countries with different regulations.
                          </dd>
                        </dl>
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="col-lg-8 col-md-12 col-sm-12 wow fadeInRight"
                  data-wow-delay="1.0s"
                >
                  <img
                    src="images/test/game1.jpg"
                    alt="Esports Betting Game Service"
                  />
                </div>
              </div>
              <div className="row sec">
                <div
                  className="col-lg-8 col-md-12 col-sm-12 wow fadeInLeft"
                  data-wow-delay="0.4s"
                >
                  <img
                    src="images/game/game4.png"
                    alt="Support for mobile devices"
                  />
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <dl>
                    <dt className="wow fadeInRight" data-wow-delay="0.6s">
                      Support for mobile devices
                    </dt>
                    <dd className="wow fadeInRight" data-wow-delay="0.7s">
                      Connection available without installing a separate
                      application
                    </dd>
                    <dd className="wow fadeInRight" data-wow-delay="0.8s">
                      Minimization of web server traffic
                    </dd>
                    <dd className="wow fadeInRight" data-wow-delay="0.9s">
                      Contests can be viewed even on a small screen
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </section>

          <section id="platform" className="sub_cont">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p className="title wow fadeInDown" data-wow-delay="0.2s">
                    2WinChance Platform
                  </p>
                  <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                    Goals for successful settlement
                  </h4>
                  <img
                    src="images/game/platform_en.png"
                    alt="Goals for successful settlement"
                    className="wow fadeInDown"
                    data-wow-delay="0.6s"
                  />
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="box">
                    <p>Step 1</p>
                    <dl>
                      <dt>Esports 2WinChance Platform Development</dt>
                      <dd>
                        Developed fantasy sports game that proved to be a hit in
                        the U.S. as metagame to suit Esports
                      </dd>
                    </dl>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                  data-wow-delay="0.9s"
                >
                  <div className="box">
                    <p>Step 2</p>
                    <dl>
                      <dt>Preparation for release in Korea</dt>
                      <dd>
                        Progress of game classification through government
                        agencies
                      </dd>
                      <dd>
                        Provide information on real-time game data from popular
                        Esports professional gamers
                      </dd>
                    </dl>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                  data-wow-delay="1.0s"
                >
                  <div className="box">
                    <p>Step 3</p>
                    <dl>
                      <dt>Preparation for global release</dt>
                      <dd>
                        Preoccupation with Southeast Asian market with high
                        interest in Esports
                      </dd>
                      <dd>
                        Entry into additional big markets such as North America
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="gameContact" className="sub_contact">
            <div className="container">
              <div className="row">
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                  Want to join 2WinChance?
                </h4>
                {/* <button
                    title="contact"
                    className="btn_contact wow fadeInUp"
                    data-wow-delay="0.8s"
                    onClick={() => {
                    onclickModalEn();
                    }}
                    >
                    CONTACT US
                  </button> */}
                        <Link
                to="/contact_en"
                title="contact"
                className="btn_contact wow fadeInUp"
                data-wow-delay="0\.8s"
              >
                CONTACT US
              </Link>
              </div>
            </div>
          </section>
        </div>
        {isOpenContactEn ? (
              <ContactModalEn setIsOpenContactEn={setIsOpenContactEn} />
            ) : null}
      </Layout>
    </div>
  );
};

export default GameEn;

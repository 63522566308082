import React ,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'

const Header = () => {
  useEffect(() => {
    $("header .gnb_pc").hover(function (e) {
      e.stopPropagation();
      e.preventDefault();
      $("header h1 img").attr("src", "images/logo/logo.png");
      $("header").css({
        "background-color": "rgba(255, 255, 255, 1)",
        "box-shadow": "0 8px 32px 0 rgba(31, 38, 135, 0.1)",
        "border-bottom": "1px solid rgba(255, 255, 255, 0.18)"
      });
      $("header .gnb_pc a").css("color", "#000");
      $(this).find(".dropdown").stop().slideDown(200);
    });
    
    $("header .gnb_pc").mouseleave(function (e) {
      e.stopPropagation();
      e.preventDefault();
      $("header h1 img").attr("src", "images/logo/logo-white.png");
      $("header").css({
        "background-color": "rgba(0, 0, 0, 0.6)",
        "box-shadow": "0 8px 32px 0 rgba(31, 38, 135, 0.1)",
        "border-bottom": "1px solid rgba(255, 255, 255, 0.18)"
      });
      $("header .gnb_pc .menu > li > a").css("color", "#fff");
      $("header .gnb_pc .lang > li > a").css("color", "#fff");
      $(this).find(".dropdown").stop().slideUp(500);
    });

  }, []);
  return (
    <header>
      <h1>
        <Link to="/"><img src={`${process.env.PUBLIC_URL}/images/logo/logo-white.png`} alt="logo" /></Link>
      </h1>
      <nav>
        <h2 className="hidden">메인메뉴</h2>
        <div className="gnb_pc clear">
          <ul className="menu clear">
            <li>
              <a title="metarock" >METAROCK</a>
            </li>
            <li>
              <a title="비즈니스">BUSINESS</a>
              
            </li>
            <li>
              <a title="center">PR CENTER</a>
            </li>
            <li>
              <a title="careers">CAREERS</a>
            </li>
            <li><Link to={'/contact'} title="contact">CONTACT</Link></li>
          </ul>
          <div className="dropdown">
          <ul>
              <li>
       
              <ul>
                <li><Link to={'/introduce'} title="회사소개">회사소개</Link></li>
                <li><Link to={'/history'} title="히스토리">History</Link></li>
                <li><Link to={'/patent'} title="특허자료 및인허가자료">특허 및 인허가</Link></li>
                <li><Link to={'/partner'} title="파트너사">파트너사</Link></li>
              </ul>
            </li>
              <li>

              <ul>
                <li><Link to={'/metaverse'} title="닷큐브프로토콜">DotCube Protocol</Link></li>
                <li><Link to={'/game'} title="투윈챈스">2WinChance</Link></li>
                <li><Link to={'/heaven'} title="헤븐">Heaven</Link></li>
                <li><Link to={'/mozza'} title="모짜와살리">Mozza &amp; Sali</Link></li>
              </ul>
            </li>
            <li>

              <ul>
                <li><Link to={'/notice'} title="공지사항">공지사항</Link></li>
                <li><Link to={'/news'} title="메타록뉴스">메타록 뉴스</Link></li>
                <li><Link to={'/sns'} title="소셜미디어">소셜미디어</Link></li>
              </ul>
            </li>
            <li>
              <ul>
                <li><Link to={'/hire'} title="인재채용">인재채용</Link></li>
                <li><Link to={'/faq'} title="채용faq">채용 FAQ</Link></li>
              </ul>
            </li>
            <li>
              <ul>
                  <li style={{ width:"216px" }}><Link to={'/contact'} title="contact">CONTACT</Link></li>
              </ul>
            </li>
    
          </ul>

          </div>
          {/* <ul className="menu clear">
            <li>
              <a title="metarock" >METAROCK</a>
              <ul className="dropdown">
                <li><Link to={'/introduce'} title="회사소개">회사소개</Link></li>
                <li><Link to={'/history'} title="히스토리">History</Link></li>
                <li><Link to={'/patent'} title="특허자료 및인허가자료">특허 및 인허가</Link></li>
                <li><Link to={'/partner'} title="파트너사">파트너사</Link></li>

              </ul>
            </li>
            <li>
              <a title="비즈니스">BUSINESS</a>
              <ul className="dropdown">
                <li><Link to={'/metaverse'} title="닷큐브프로토콜">DotCube Protocol</Link></li>
                <li><Link to={'/game'} title="투윈챈스">2WinChance</Link></li>
                <li><Link to={'/heaven'} title="헤븐">Heaven</Link></li>
                <li><Link to={'/mozza'} title="모짜와살리">Mozza &amp; Sali</Link></li>
              </ul>
            </li>
            <li>
              <a title="center">PR CENTER</a>
              <ul className="dropdown">
                <li><Link to={'/notice'} title="공지사항">공지사항</Link></li>
                <li><Link to={'/news'} title="메타록뉴스">메타록 뉴스</Link></li>
                <li><Link to={'/sns'} title="소셜미디어">소셜미디어</Link></li>
              </ul>
            </li>
            <li>
              <a title="careers">CAREERS</a>
              <ul className="dropdown">
                <li><Link to={'/hire'} title="인재채용">인재채용</Link></li>
                <li><Link to={'/faq'} title="채용faq">채용 FAQ</Link></li>
              </ul>
            </li>
            <li><Link to={'/contact'} title="contact">CONTACT</Link></li>
          </ul> */}
          <ul className="lang">
            <li>
              <a style={{ cursor: 'pointer' }}><span className="material-icons">language</span></a>
              <ul>
                <li className="on">
                  <Link to={'/'}>KOR</Link>
                </li>
                <li>
                  <Link to={'/home_en'} title="eng">ENG</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="side_menu">
          <div className="burger_box">
            <div className="menu-icon-container">
              <a href="#!" title="menu" className="menu-icon js-menu_toggle closed">
                <span className="menu-icon_box">
                  <span className="menu-icon_line menu-icon_line--1"></span>
                  <span className="menu-icon_line menu-icon_line--2"></span>
                  <span className="menu-icon_line menu-icon_line--3"></span>
                </span>
              </a>
            </div>
          </div>
          <Link to="/">
          <img src="/images/logo/logo.png" alt="METAROCK_LOGO" className='side_menu_logo'/>
          </Link>
          <ul className="lang clear">
            <li className="on">
              <Link to={'/'} title="kor">
                {/* <img src="/images/nation/lang_ko.png" alt="KOREA" /> */}
                <p>KOR</p>
              </Link>
            </li>
            <li>
              <Link to={'/home_en'} title="eng">
                {/* <img src="/images/nation/lang_en.png" alt="ENGLISH" /> */}
                <p>ENG</p>
              </Link>
            </li>
          </ul>
          <div className="gnb_m">
            <ul className="clear">
              <li className="has_dropdown">
                <a href="#!" title="metarock">METAROCK<i className="far fa-chevron-down"></i></a>
                <ul className="dropdown">
                  <li><Link to={'/introduce'} title="회사소개">회사소개</Link ></li>
                  <li><Link to={'/history'} title="히스토리">History</Link ></li>
                  <li><Link to={'/patent'} title="특허자료 및인허가자료">특허 및 인허가</Link></li>
                  <li><Link to={'/partner'} title="파트너사">파트너사</Link></li>

                </ul>
              </li>
              <li className="has_dropdown">
                <a href="#!" title="비즈니스">BUSINESS<i className="far fa-chevron-down"></i></a>
                <ul className="dropdown">
                  <li><Link to={'/metaverse'} title="닷큐브프토토콜">DotCube Protocol</Link></li>
                  <li><Link to={'/game'} title="투윈챈스">2WinChance</Link></li>
                  <li><Link to={'/heaven'} title="헤븐">Heaven</Link></li>
                  <li><Link to={'/mozza'} title="모짜와 살리">Mozza &amp; Sali</Link></li>
                </ul>
              </li>
              <li className="has_dropdown">
                <a href="#!" title="center">PR CENTER<i className="far fa-chevron-down"></i></a>
                <ul className="dropdown">
                <li><Link to={'/notice'} title="공지사항">공지사항</Link></li>
                <li><Link to={'/news'} title="메타록뉴스">메타록 뉴스</Link></li>
                <li><Link to={'/sns'} title="소셜미디어">소셜미디어</Link></li>
                </ul>
              </li>
              <li className="has_dropdown">
                <a href="#!" title="careers">CAREERS<i className="far fa-chevron-down"></i></a>
                <ul className="dropdown">
                  <li><Link to={'/hire'} title="인재채용">인재채용</Link></li>
                  <li><Link to={'/faq'} title="채용faq">채용 FAQ</Link></li>
                </ul>
              </li>
              <li> <Link to={'/contact'} title="contact">CONTACT</Link></li>
            </ul>
          </div >
        </div >
      </nav >
    </header >
  )
}

export default Header
import React ,{ useState } from 'react'
import Footer from '../../components/ko/Footer'
import Header from '../../components/ko/Header'
import ContactModal from '../../components/ko/ContactModal';
import { Link } from 'react-router-dom';

const Hire = () => {
  const [isOpenContact, setIsOpenContact] = useState(false);
  const onclickModal = () =>{
    setIsOpenContact(true)
  }
  return (
    <div className="wrap">
      <Header />
      <div id="content">
        <section id="hireMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">Careers · 인재채용</p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">메타버스로 연결된 새로운 세상을 함께 만들어 갈<br />분야별 전문가를
                  모십니다.</h3>
                <p className="wow fadeInDown" data-wow-delay="0.6s">METAROCK과 함께하기 위한 기회는 언제든 열려있습니다.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="hire">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.2s">
                <h4>곧 다가올 메타버스 시대를 위한 <br className="pc" />핵심 기술의 개발과 성장. <br className="pc" />바로, METAROCK이 지향하는
                  가치입니다.</h4>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <p className="wow fadeInRight" data-wow-delay="0.4s">METAROCK은 차별화된 IT 기술을 주축으로 크게 메타버스, 게임 등
                  다양한 사업을 추진하고 있습니다. 머지 않아 다가올 대변화에 있어 각 분야에 기술적 혁신을 창출하여, 삶의 질을 한단계 더 높이고자
                  합니다.<br /><br />당사와 함께 새로운 세상을 함께 만들어갈 인재를 모시고 있으니 아래의 모집 분야에 관심 가져주시면 감사하겠습니다.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="value" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">Our Core Value</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">METAROCK의 핵심 인재상</h4>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.6s">
                <div className="thumb">
                  <img src={`${process.env.PUBLIC_URL}/images/value/value1.png`} alt="도전 정신" />
                </div>
                <dl>
                  <dt>도전 정신</dt>
                  <dd>긍정적인 자세로 끊임없는 혁신을 추구합니다.</dd>
                </dl>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                <div className="thumb">
                  <img src={`${process.env.PUBLIC_URL}/images/value/value2.png`} alt="협력과 조화" />
                </div>
                <dl>
                  <dt>협력과 조화</dt>
                  <dd>언제나 서로를 배려하고 존중하며, 상호소통합니다.</dd>
                </dl>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.0s">
                <div className="thumb">
                  <img src={`${process.env.PUBLIC_URL}/images/value/value3.png`} alt="전문지식" />
                </div>
                <dl>
                  <dt>전문지식</dt>
                  <dd>자기 분야의 최고가 되겠다는 목표와 의식을 가집니다.</dd>
                </dl>
              </div>
            </div>
          </div>
        </section>

        <section id="hireFaq">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-8 col-sm-12">
                <h5 className="wow fadeInLeft" data-wow-delay="0.4s">채용과 관련해 궁금하신 사항이 있으신가요?</h5>
                <p className="wow fadeInLeft" data-wow-delay="0.8s">FAQ를 통해 궁금증을 해소해보세요.</p>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 wow fadeInRight" data-wow-delay="1.2s">
                <Link to="/faq" title="faq" className="btn_faq">FAQ 보러가기</Link>
              </div>
            </div>
          </div>
        </section>

        <section id="recruitment" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">Recruitment</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">채용분야 안내</h4>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInLeft" data-wow-delay="0.2s">
                <p>게임 개발</p>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <ul>
                  <li className="wow fadeInRight" data-wow-delay="0.3s">게임 기획/스토리설정, 시스템구성요소 설계 및 기획</li>
                  <li className="wow fadeInRight" data-wow-delay="0.35s">프로그램/서버 프로그래밍, 클라이언트 프로그래밍</li>
                  <li className="wow fadeInRight" data-wow-delay="0.4s">아트/2D, 3D 그래픽 디자인, 원화, 모델링</li>
                  <li className="wow fadeInRight" data-wow-delay="0.45s">연출/이펙트 및 효과음 UI/UX</li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInLeft" data-wow-delay="0.5s">
                <p>VR 사업</p>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <ul>
                  <li className="wow fadeInRight" data-wow-delay="0.55s">VR 사업/기획, 분석, 통계, 수요 분석, 제휴 프로모션 기획
                    실행</li>
                  <li className="wow fadeInRight" data-wow-delay="0.6s">VR 개발/기획, 프로그래밍, 아트</li>
                  <li className="wow fadeInRight" data-wow-delay="0.65s">VR 운영/VR Market 유경험자</li>
                  <li className="wow fadeInRight" data-wow-delay="0.7s">기획,설계,커뮤니케이션,개발,운영에 적극 참여하실 수 있으신 분
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                <p>사업</p>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <ul>
                  <li className="wow fadeInRight" data-wow-delay="0.85s">사업기획/게임 통계, 고객수요 분석, 제휴 프로모션 기획 및 실행
                  </li>
                  <li className="wow fadeInRight" data-wow-delay="0.9s">소싱/매출 및 비용 분석 등 퍼블리싱</li>
                  <li className="wow fadeInRight" data-wow-delay="0.95s">마케팅 및 홍보/온오프라인 광고, 프로모션 기획 및 실행</li>
                  <li className="wow fadeInRight" data-wow-delay="1.0s">해외사업/해외 트랜드 파악 및 데이터 분석</li>
                  <li className="wow fadeInRight" data-wow-delay="1.05s">운영/게임 운영 및 메타버스 운영 및 QA</li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInLeft" data-wow-delay="1.1s">
                <p>경영 지원</p>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <ul>
                  <li className="wow fadeInRight" data-wow-delay="1.15s">경영기획/연간 사업계획 수립 및 관리, 손익 및 예산 관리</li>
                  <li className="wow fadeInRight" data-wow-delay="1.2s">재무/회계, 예산관리, 재무회계, 세무회계, 재무관련 검토 및 분석
                  </li>
                  <li className="wow fadeInRight" data-wow-delay="1.25s">인사/분석인사채용, 평가, 보상에 관련된 전반적인 업무 기획 및
                    운영</li>
                  <li className="wow fadeInRight" data-wow-delay="1.3s">총무/효율적인 자산관리, 적절한 사무환경 제공</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="hireContact" className="sub_contact">
          <div className="container">
            <div className="row">
              <h4 className="wow fadeInDown" data-wow-delay="0.4s">METAROCK과 함께하고 싶으신가요?</h4>
              {/* <button
    title="contact" 
    className="btn_contact wow fadeInUp"
    data-wow-delay="0.8s"
    onClick={() => {
      onclickModal()
    }}
  >
    CONTACT US
              </button> */}
                <Link to={"/contact"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
              
            </div>
          </div>
        </section>
      </div>
      {isOpenContact ? (
              <ContactModal setIsOpenContact={setIsOpenContact} />
            ) : null}
      <footer>
        <div className="container">
          <Footer />
        </div>
      </footer>
    </div>
  )
}

export default Hire
import { Dispatch, SetStateAction } from "react";

const ContactModal = ({
  setIsOpenContact,
}: {
  setIsOpenContact: Dispatch<SetStateAction<boolean>>;
}) => {
  const closeContact = () => {
    setIsOpenContact(false);
  };

  return (
    <div id="popup_layer" className="contact_popup">
        <div>
        <div className="layer_popup_header">
        <h1>Contact Us</h1>
        </div>      
      <div id="Contactlayer_popup" className="row">
     
        <div className="layer_scroll">
          <form>
            <label>
              <span className="title">소속기관<sup>*</sup></span>
              <input type="text" />
            </label>
            <label>
              <span className="title">부서명</span>
              <input type="text" />
            </label>
            <label>
              <span className="title">이름<sup>*</sup></span>
              <input type="text" />
            </label>
            <label>
              <span className="title">직위</span>
              <input type="text" />
            </label>
            <label>
              <span className="title">연락처</span>
              <input type="text" />
            </label>
            <label>
              <span className="title">이메일<sup>*</sup></span>
              <input type="text" />
            </label>
            <label className="Category">
              <span className="title">구분<sup>*</sup></span>
                <select className="Category_select">
                  <option>메타버스 구축</option>
                  <option>메타버스 제휴</option>
                  <option>게임 퍼블리싱</option>
                  <option>업무 제휴</option>
                  <option>기타</option>
              </select>
            </label>
            <label>
              <span className="title">제목<sup>*</sup></span>
              <input type="text" />
            </label>
            <label className="ContText">
                <span className="title">내용<sup>*</sup></span>
                <textarea placeholder="내용을 입력해주세요." id="contentText" name="contentText" required>
                  
                </textarea>
            </label>
            <label>
              <span className="title">파일첨부</span>
              <input type="file" className="browse" />
              </label>
              <label className="Contactalert">
              보내기 버튼을 누르시면 개인정보 수집/이용에 대해 동의한 것으로 간주합니다.
              </label>
        </form>
          

        </div>
        <div className="Contactmedal_btn">
        <button className="send_btn">보내기</button>
        <button className="close_btn" onClick={closeContact}>닫기</button>
        </div>
    </div >
  </div >

    </div >
  )
}

export default ContactModal;
import React, { useState, useEffect } from "react";
import Footer from "../../../components/ko/Footer";
import Header from "../../../components/ko/Header";
import { Link, useLocation } from "react-router-dom";
import {
  get_articleDetail,
  uset_boardReadCntIncrease,
} from "../../../utils/axios";

interface articleDataInterface {
  regId: string;
  regDt: string;
  articleContent: string;
  articleSubject: string;
}

const News = () => {
  const [articleDetail, setArticleDetail] =
    useState<articleDataInterface | null>(null);
  const [articleFilesList, setArticleFilesList] = useState([]);
  const location = useLocation();

  const get_articleDatail = async (id: string) => {
    const params = {
      boardIdx: "2",
      articleIdx: id,
    };
    try {
      const { data } = await get_articleDetail(params);
      setArticleDetail(data.articleDetail);
      setArticleFilesList(data.articleFilesList);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const articleId = location.search.split("t=")[1];

    get_articleDatail(articleId);
    uset_boardReadCntIncrease(articleId);
  }, []);

  return (
    <div className="wrap">
      <Header />
      <div id="content">
        <section id="noticeMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">
                  PR Center · 공지사항
                </p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">
                  METAROCK 공지사항
                </h3>
              </div>
            </div>
          </div>
        </section>

        <section id="noticeDetailCont">
          <div className="container">
            <h2 className="noticeTitle wow fadeIn">공지사항</h2>
            <div className="noticeDetailHeader wow fadeInLeft">
              <p className="title">{articleDetail?.articleSubject}</p>
              <ul className="noticeListInfo">
                <li>{articleDetail?.regId}</li>
                <li>{articleDetail?.regDt}</li>
              </ul>
            </div>
            {articleDetail?.articleContent ? (
              <div
                className="noticeView wow fadeInLeft sun-editor-editable"
                dangerouslySetInnerHTML={{
                  __html: articleDetail.articleContent,
                }}
              ></div>
            ) : null}
            {articleFilesList.map(({ file_name, file_url }, i) => {
              return (
                <a
                  href={file_url}
                  key={i}
                  className="noticeFile wow fadeInLeft"
                  rel="noopener"
                >
                  {file_name}
                </a>
              );
            })}
            <Link to="/notice" className="noticeBtn wow fadeInRight">
              List
            </Link>
          </div>
        </section>

        <section id="noticeContact" className="sub_contact">
          <div className="container">
            <div className="row">
              <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                메타록에 대해 문의할 사항이 있으신가요?
              </h4>
              <Link
                to={"/contact"}
                className="btn_contact wow fadeInUp"
                data-wow-delay="0.8s"
              >
                CONTACT US
              </Link>
            </div>
          </div>
        </section>
      </div>
      <footer>
        <div className="container">
          <Footer />
        </div>
      </footer>
    </div>
  );
};

export default News;

import React ,{useState} from 'react';
import Layout from '../../components/en/Layout';
import 'swiper/css/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
import  SwiperCore,{ Autoplay,EffectFade } from 'swiper';
import ContactModalEn from '../../components/en/ContactModalEn';
import { Link } from "react-router-dom";

const MetaverseEn: React.FC = () => {
    SwiperCore.use([Autoplay]);
  const [isOpenContactEn, setIsOpenContactEn] = useState(false);
  const onclickModalEn = () => {
    setIsOpenContactEn(true);
  };
  return (
    <Layout>
      <div id="content">
            <section id="metaverseMain" className="sub_main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">BUSINESS · Dotcube protocol</p>
                            <h3 className="wow fadeInDown" data-wow-delay="0.4s">We lead you to a new world<br />through the
                                metaverse.</h3>
                            <p className="text wow fadeInDown" data-wow-delay="0.2s">METAROCK Dotcube Protocol</p>
                        </div>
                    </div>
                    <Swiper autoplay={{delay: 4000, disableOnInteraction: false}} modules={[EffectFade]} effect="fade" allowTouchMove={false} speed={2000} className="swiper mySwiper swiper-container">
                        <SwiperSlide className="swiper-slide"></SwiperSlide>
                        <SwiperSlide className="swiper-slide"></SwiperSlide>
                        <SwiperSlide className="swiper-slide"></SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <section id="metaFeature" className="sub_cont">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">Metaverse is future</p>
                            <h4 className="wow fadeInDown" data-wow-delay="0.4s">Where is our society going now?</h4>
                            <p className="text wow fadeInDown" data-wow-delay="0.6s">The world is always changing all of a
                                sudden. So we need to prepare for the situations before, now and after COVID-19.</p>
                            <div className="line"></div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                            <dl>
                                <dt>Contact</dt>
                                <dd>A way in which services were<br />provided face-to-face with people<br />(Last
                                    Years)</dd>
                            </dl>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.9s">
                            <dl>
                                <dt>Un-tact</dt>
                                <dd>Changed to a non-face-to-face method<br />without human contact due to
                                    Corona<br />(Next Few Years)</dd>
                            </dl>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.0s">
                            <dl>
                                <dt>On-tact</dt>
                                <dd>A concept that adds 'On'<br />with the outside world<br />through online to
                                    'Untact'<br />(Next Few Years)</dd>
                            </dl>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.1s">
                            <dl>
                                <dt>Metaverse</dt>
                                <dd>Metaverse is the future of society.<br />A world for new
                                    connections,<br />communication and cooperation<br />(After Corona19)</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </section>

            <section id="vision" className="sub_cont">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">Metaverse Network based on DotCube Protocol</p>
                            <h4 className="wow fadeInDown" data-wow-delay="0.4s">DotCube Protocol Metaverse Network</h4>
                            <img src="images/metaverse/ecosystem_en.png" alt="DotCube Protocol Metaverse Network"
                                className="pc_img wow fadeInUp" data-wow-delay="0.6s" />
                            <img src="images/metaverse/ecosystem_m_en.png" alt="DotCube Protocol Metaverse Network"
                                className="m_img wow fadeInUp" data-wow-delay="0.6s" />
                            <p className="text wow fadeInUp" data-wow-delay="0.8s">METAROCK attempts to create new value by
                                incorporating Metaverse into various industrial fields.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="methodology" className="sub_cont">
                <div className="container">
                    <div className="row" style={{justifyContent:'center'}}>
                          <div className="col-12">
                         
                            <h4 className="wow fadeInDown" data-wow-delay="0.2s">Direction of DotCube Protocol
</h4>
                        </div>
                        <div className='dotcube_direction'>
                <ul className='dotcube1 col-lg-3 col-md-6 col-sm-12 wow fadeInUp' data-wow-delay="0.8s">
                  <li className='tit fadeInUp'>Existed Metaverse</li>
                  <li>Service User Network Focused on the space of the platform</li>
                  <li>Mega-Servitization of Large Platforms<span style={{fontSize:'0.8rem',width:'100%'}}>&#40; Proceeds exclusive centralization &#41;
                  </span> </li>
                  <li>Platform Orientation and Isolation of Independent Services<span style={{fontSize:'0.8rem',}}>
                  &#40;For small-scale services due to barriers to entry of their own services,Isolation due to platforming and marketing constraints&#41;
                  </span></li>
                  <li>Limited user experience due to platform constraints
                    <span style={{fontSize:'0.8rem',}}>
                    &#40;Limited technical specifications and spatial diversity constraints&#41;
                    </span>
                  </li>
                  <li>Limitations of Business Expansion by centralized platform services</li>
                </ul>
                <span className='arrow'><img src={`${process.env.PUBLIC_URL}/images/metaverse/caret-right-solid2.svg`} alt="right-arrow" className="wow arrow_led" style={{animationName:"arrow_led", animationIterationCount:"infinite", animationDuration:"0.9s"}} data-wow-delay="0.6s"></img></span>
                <ul className='dotcube2 col-lg-3 col-md-6 col-sm-12 wow fadeInUp' data-wow-delay="0.6s">
                  <li className='tit fadeInUp'>Metaverse Service Orientation</li>
                  <li>Service structure for extended user experience</li>
                  <li>Representative service frame of Web 3.0 Decentralization-based services</li>
                  <li>Need to communicate and share through networking between individual services</li>
                  <li>5G-based network, Computing Power, Diversification of user hardware, etc.. Enhanced user experience with based infrastructure</li>
                  <li>Combined with Web 3.0 services such as blockchain that can put the business ecosystem Metaverse Services as Biz Platform</li>
                </ul>
                <div className='arrow_g' style={{alignSelf:"center"}}>
                <span className='arrow'><img src={`${process.env.PUBLIC_URL}/images/metaverse/caret-right-solid2.svg`} alt="right-arrow" className="wow arrow_led" data-wow-delay="0.9s"></img></span>
                <span className='arrow'><img src={`${process.env.PUBLIC_URL}/images/metaverse/caret-right-solid.svg`} alt="right-arrow" className="wow arrow_led" data-wow-delay="1.5s"></img></span>
                </div>
                <ul className='dotcube3 col-lg-3 col-md-6 col-sm-12 wow fadeInUp' data-wow-delay="1.0s">
                  <li className='tit fadeInUp' data-wow-delay="1.2s">Target of DotCube Protocol</li>
                  <li>Focused on the user's individual experience diversified and  systematic Expanded Metaverse World Implementation 
                  </li>
                  <li>Realization of Metaverse Network Services Based on Decentralization</li>
                  <li>Provides a technology foundation for connecting networks and user networks between services with individual business characteristics</li>
                  <li>Configure the Technical Platform for Service Scalability

                    <span style={{fontSize:'0.8rem',}}>
                    &#40; Securing a technology foundation to strengthen user immerse&#41; 
                    </span>
                  </li>
                                  <li>
                                  Establish an open structure that ensures the independence of individual services while sharing diverse resources within the network           
                  </li>
                 </ul>
              </div>
                    </div>
                </div>
            </section>

            <section id="status" className="sub_cont">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">Development status</p>
                            <h4 className="wow fadeInDown" data-wow-delay="0.4s">Metaverse platform <br
                                    className="m" />currently being developed</h4>
                        </div>
                        <div className="status_body row">
                <Swiper
                  autoplay={{
                delay: 4000,
                disableOnInteraction: false,
                  }}
                  slidesPerView={6}
                 modules={[Autoplay]}
                  spaceBetween={15}
                  loop={true}
                  speed={4000}
                  breakpoints={{
                    320: {
                      slidesPerView: 1
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 15
                    },
                    1024: {
                      slidesPerView: 3
                    }
                  }}
                >

                <SwiperSlide>
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status1.png`} alt="status1" className="wow fadeInUp"></img>
                     </div>
                        <div className="back">
                          <div className="text">
                          <h1>TVM</h1>
                          <p>La Liga Metaverse</p>
                          </div>
                     </div>
                   </div>
                </div>                    
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status4.jpg`} alt="status4" className="wow fadeInUp"></img>
                     </div>
                    <div className="back">
                    <div className="text">
                          <h1>TuneGem</h1>
                          <p>K-pop Audition Metaverse</p>
                          </div>
                     </div>
                   </div>
                    </div>     
                    </SwiperSlide>    
                  <SwiperSlide>
                  <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status2.jpg`} alt="status2" className="wow fadeInUp"></img>
                     </div>
                        <div className="back">
                          <div className="text">
                          <h1>MABC</h1>
                          <p>MABC Art Metaverse</p>
                          </div>
                     </div>
                   </div>
                </div>                    
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status5.jpg`} alt="status5" className="wow fadeInUp"></img>
                     </div>
                    <div className="back">
                    <div className="text">
                          <h1>Paros labs</h1>
                          <p>Survey Services based on Metaverse</p>
                          </div>
                     </div>
                   </div>
                    </div>     
                  </SwiperSlide>
                  <SwiperSlide>
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status3.jpg`} alt="status3" className="wow fadeInUp"></img>
                     </div>
                        <div className="back">
                          <div className="text">
                            <h1>AIM Music</h1>
                            <p>AIMY Moon Metaverse</p>
                          </div>
                     </div>
                   </div>
                </div>                    
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status9.jpg`} alt="status9" className="wow fadeInUp"></img>
                     </div>
                    <div className="back">
                    <div className="text">
                            <h1>MONGMONG-E</h1>
                            <p>Pet Metaverse</p>
                          </div>
                     </div>
                   </div>
                </div>         
                  </SwiperSlide>
                  <SwiperSlide>
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status7.jpg`} alt="status7" className="wow fadeInUp"></img>
                     </div>
                    <div className="back">
                    <div className="text">
                            <h1>7billion Labs</h1>
                            <p>Still duck Universe club (SDUC)</p>
                          </div>
                     </div>
                   </div>
                </div>                    
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status8.jpg`} alt="status8" className="wow fadeInUp"></img>
                     </div>
                    <div className="back">
                    <div className="text">
                            <h1>Tomato Chain</h1>
                            <p>Platform Services based on Metaverse</p>
                          </div>
                     </div>
                   </div>
                </div>                              
              </SwiperSlide>
              <SwiperSlide>                
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status.jpg`} alt="status" className="wow fadeInUp"></img>
                     </div>
                        <div className="back">
                          <div className="text">
                            <h1>Preparing..</h1>
                            <p></p>
                          </div>
                     </div>
                   </div>
                </div>
                {/*}
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status6.jpg`} alt="status6" className="wow fadeInUp"></img>
                     </div>
                    <div className="back">
                    <div className="text">
                            <h1>PicoenTech</h1>
                            <p>brain health management metaverse service for senior based on Big data</p>
                          </div>
                     </div>
                   </div>
                </div>                    
                */}
              </SwiperSlide>
                
              </Swiper>
              </div>
              
                   
                    </div>
                </div>
            </section>

            <section id="metaverseContact" className="sub_contact">
                <div className="container">
                    <div className="row">
                        <h4 className="wow fadeInDown" data-wow-delay="0.4s">Want to join METAROCK?</h4>
                        {/* <button
                    title="contact"
                    className="btn_contact wow fadeInUp"
                    data-wow-delay="0.8s"
                    onClick={() => {
                    onclickModalEn();
                    }}
                    >
                    CONTACT US
                  </button> */}
                 <Link to={"/contact_en"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
                    </div>
                </div>
            </section>
      </div>
      {isOpenContactEn ? (
              <ContactModalEn setIsOpenContactEn={setIsOpenContactEn} />
            ) : null}
    </Layout>
  );
};

export default MetaverseEn;
import React, {useState} from 'react';
import Layout from '../../components/en/Layout';
import ContactModalEn from '../../components/en/ContactModalEn';

const ContactEn: React.FC = () => {
    const [isOpenContactEn, setIsOpenContactEn] = useState(false);
  return (
    <Layout contect>
      <div id="content2">
            <section id="contactMain" className="sub_main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">Contact Us</p>
                            <h3 className="wow fadeInDown" data-wow-delay="0.4s">Metarock is always with you.<br />Find us.
                            </h3>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contactCont">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.6s">
                            <dl>
                                <dt></dt>
                                <dd className='en'>8F, 703, seolleung-ro, Gangnam-gu, <br />Seoul, Republic of Korea</dd>
                            </dl>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 wow fadeInRight" data-wow-delay="0.8s">
                            <img src="images/logo/logo_contact_en.png" alt="METAROCK" />
                            <dl className="tel clear">
                                <dt>Tel.</dt>
                                <dd>02-515-0630</dd>
                            </dl>
                            <dl className="mail clear">
                                <dt>E-Mail.</dt>
                                <dd><a href="mailto:biz@metarock.co.kr"
                                        title="e-mail">biz@metarock.co.kr</a></dd>
                            </dl>
                        </div>
                        <div className="col-12 wow fadeInUp" data-wow-delay="1.0s">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.5869079069375!2d127.03855221611423!3d37.5176600346819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca475700518d7%3A0x4cd448203eb5cddb!2z7ISc7Jq47Yq567OE7IucIOqwleuCqOq1rCDshKDrponroZwgNzAz!5e0!3m2!1sko!2skr!4v1646706896786!5m2!1sko!2skr"
                                title="metarock_map" width="100%" height="450"
                                loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </section>
          </div>
          {isOpenContactEn ? (
              <ContactModalEn setIsOpenContactEn={setIsOpenContactEn} />
            ) : null}
    </Layout>
  );
};

export default ContactEn;
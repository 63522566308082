import React from 'react';
import AppRouter from './router/Router';

function App() {
  return (
    <div className="App">
      <div className="wrap">
        <AppRouter />
      </div>
    </div>
  );
}

export default App;

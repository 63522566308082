import React from "react";


const ShrtcButon: React.FC = () => {
  return (
    <button onClick={() => window.open('http://www.dotcubeprotocol.com/', '_blank')}  title="딧큐브프로토콜 바로가기" className="btn_shrtc">
      <img src={`${process.env.PUBLIC_URL}/images/logo/dotcube.png`} alt="Dotcubelogo" />
    </button>
  );
};

export default ShrtcButon;

import React ,{ useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, EffectFade } from "swiper";
import 'swiper/css/autoplay';
import "swiper/css/effect-fade";
import Header from '../../components/ko/Header';
import Footer from '../../components/ko/Footer';
import ContactModal from '../../components/ko/ContactModal';
import { Link } from 'react-router-dom';


const Heaven = () => {
  const [isOpenContact, setIsOpenContact] = useState(false);
  const onclickModal = () =>{
    setIsOpenContact(true)
  }
  return (
    <div className="wrap">
      <Header />
      <div id="content">
        <section id="heavenMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">BUSINESS · Heaven</p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">Heaven, 차세대<br />서바이벌 배틀 게임의 표준</h3>
                <p className="text wow fadeInDown" data-wow-delay="0.2s">Heaven은 DOTA/CHAOS 등 글로벌 인기 게임을 모티브로
                  탄생한 Aeon Of Strife(AOS) 게임입니다.</p>
              </div>
            </div>
            <Swiper
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              centeredSlides={true}
              modules={[Autoplay, EffectFade]}
              effect={"fade"}
              spaceBetween={50}
              slidesPerView={1}
              speed={2000}
            >
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
            </Swiper>
          </div>
        </section>

        <section id="feature" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">Our feature</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">E-스포츠용 게임, Heaven</h4>
                <p className="text wow fadeInDown" data-wow-delay="0.6s">빠르고 정교한 컨트롤, 그리고 순간적인 센스가 승패를 좌우합니다.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                <div className="thumb">
                  <img src={`${process.env.PUBLIC_URL}/images/heaven/feature1.png`} alt="E-스포츠 대회 개최" />
                </div>
                <dl>
                  <dt>E-스포츠 대회 개최</dt>
                  <dd>대회를 개최함으로써 E-스포츠의 활성화와 게이머들의 적극적인 참여를 유도</dd>
                </dl>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="1.0s">
                <div className="thumb">
                  <img src={`${process.env.PUBLIC_URL}/images/heaven/feature2.png`} alt="본격 P2E GAME" />
                </div>
                <dl>
                  <dt>본격 P2E GAME</dt>
                  <dd>개인이 소유한 아이템을 NFT화하여, 당사의 마켓플레이스에서 거래하도록 지원</dd>
                </dl>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="1.2s">
                <div className="thumb">
                  <img src={`${process.env.PUBLIC_URL}/images/heaven/feature3.png`} alt="글로벌 게임 플랫폼 Steam 출시" />
                </div>
                <dl>
                  <dt>글로벌 게임 플랫폼 Steam 출시</dt>
                  <dd>글로벌 진출과 접근성 향상을 위해 Steam을 통한 게임 출시 계획</dd>
                </dl>
              </div>
            </div>
          </div>
        </section>

        <section id="special" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">Special factor</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">Heaven의 차별화 포인트</h4>
              </div>
            </div>
            <div className="row fir">
              <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.2s">
                <img src={`${process.env.PUBLIC_URL}/images/heaven/special1.gif`} alt="Heaven : Action" />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInRight" data-wow-delay="0.4s">
                <dl>
                  <dt>Heaven : Action</dt>
                  <dd>캐릭터의 화려한 액션을 보여주기 위해 다양한 모션을 구현</dd>
                  <dd>캐릭터 별 70여 가지의 다양한 모션 표현</dd>
                  <dd>캐릭터 별 액션성을 위한 기본 공격 모션, 스페셜 공격 및 콤보 공격 모션을 표현</dd>
                </dl>
              </div>
            </div>
            <div className="row sec">
              <div className="col-lg-6 col-md-12 col-sm-12 right wow fadeInRight" data-wow-delay="0.6s">
                <img src={`${process.env.PUBLIC_URL}/images/heaven/special2.gif`} alt="Heaven : Skill System" />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                <dl>
                  <dt>Heaven : Skill System</dt>
                  <dd>캐릭터 기반의 게임으로 30여 가지의 독립적인 캐릭터성을 기반으로 제작</dd>
                  <dd>각각의 캐릭터 스토리를 보유하며 캐릭터 별 연계 스토리가 게임 내에 반영되어 전략적인 캐릭터 조합에 활용</dd>
                  <dd>각 캐릭터는 10개 ~ 20개의 캐릭터 특성에 맞는 다양한 스킬을 보유함</dd>
                </dl>
              </div>
            </div>
            <div className="row thi">
              <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="1.0s">
                <img src={`${process.env.PUBLIC_URL}/images/heaven/special3.gif`} alt="Heaven : Map" />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInRight" data-wow-delay="1.2s">
                <dl>
                  <dt>Heaven : Map</dt>
                  <dd>맵 별로 대규모, 소규모 전투를 유도하는 전략성을 포함하여 유저가 다양한 전략적 플레이를 즐길 수 있도록 기획</dd>
                  <dd>맵은 기본적으로 3가지 난입 경로를 가지고 있으며, 맵에 따라 게임의 전략적 이용이 가능한 미션이 존재</dd>
                </dl>
              </div>
            </div>
          </div>
        </section>

        <section id="gallery" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">Gallery</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">인게임 이미지</h4>
                <ul>
                  <li className="wow fadeInUp" data-wow-delay="0.6s">
                    <img src={`${process.env.PUBLIC_URL}/images/heaven/gallery1.png`} alt="인게임 이미지1" />
                  </li>
                  <li className="wow fadeInUp" data-wow-delay="0.7s">
                    <img src={`${process.env.PUBLIC_URL}/images/heaven/gallery2.png`} alt="인게임 이미지2" />
                  </li>
                  <li className="wow fadeInUp" data-wow-delay="0.8s">
                    <img src={`${process.env.PUBLIC_URL}/images/heaven/gallery3.png`} alt="인게임 이미지3" />
                  </li>
                  <li className="wow fadeInUp" data-wow-delay="0.9s">
                    <img src={`${process.env.PUBLIC_URL}/images/heaven/gallery4.png`} alt="인게임 이미지4" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="heavenContact" className="sub_contact">
          <div className="container">
            <div className="row">
              <h4 className="wow fadeInDown" data-wow-delay="0.4s">Heaven에 대해 더 자세히 알고 싶으신가요?</h4>
              {/* <button
    title="contact" 
    className="btn_contact wow fadeInUp"
    data-wow-delay="0.8s"
    onClick={() => {
      onclickModal()
    }}
  >
    CONTACT US
      </button> */}
              <Link to={"/contact"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
            </div>
          </div>
        </section>
      </div>
      {isOpenContact ? (
              <ContactModal setIsOpenContact={setIsOpenContact} />
            ) : null}
      <footer>
        <div className="container">
          <Footer />
        </div>
      </footer>
    </div >
  )
}

export default Heaven
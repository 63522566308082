import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  HashRouter,
} from "react-router-dom";

import { useEffect } from "react";
import ContactEn from "../pages/en/ContactEn";
import FaqEn from "../pages/en/FaqEn";
import GameEn from "../pages/en/GameEn";
import HeavenEn from "../pages/en/HeavenEn";
import HireEn from "../pages/en/HireEn";
import HistoryEn from "../pages/en/HistoryEn";
import HomeEn from "../pages/en/HomeEn";
import IntroEn from "../pages/en/IntroEn";
import MetaverseEn from "../pages/en/MetaverseEn";
import MozzaEn from "../pages/en/MozzaEn";
import NewsEn from "../pages/en/NewsEn";
import NoticeEn from "../pages/en/Notice_en/NoticeEn";
import NoticeDetailEn from "../pages/en/Notice_en/NoticeEnDetail";
import ParentEn from "../pages/en/ParentEn";
import PartnerEn from "../pages/en/PartnerEn";
import SnsEn from "../pages/en/SnsEn";
import Homeko from "../pages/ko/Homeko";
import Introduce from "../pages/ko/Introduce";
import History from "../pages/ko/History";
import Patent from "../pages/ko/Patent";
import Partner from "../pages/ko/Partner";
import Game from "../pages/ko/Game";
import Metaverse from "../pages/ko/Metaverse";
import Heaven from "../pages/ko/Heaven";
import Mozza from "../pages/ko/Mozza";
import News from "../pages/ko/News";
import Notice from "../pages/ko/Notice/Notice";
import NoticeDetail from "../pages/ko/Notice/NoticeDetail";
import Sns from "../pages/ko/Sns";
import Hire from "../pages/ko/Hire";
import Faq from "../pages/ko/Faq";
import Contact from "../pages/ko/Contact";
import TopButton from "../components/TopButton";
import ShrtcButon from "../components/shrtcButon";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRouter: React.FC = () => {
  return (
    <HashRouter basename="/">
      <ScrollToTop />
      <TopButton />
      <ShrtcButon />
      <Routes>
        {/* English */}
        <Route path="/home_en" element={<HomeEn />} />
        <Route path="/contact_en" element={<ContactEn />} />
        <Route path="/faq_en" element={<FaqEn />} />
        <Route path="/game_en" element={<GameEn />} />
        <Route path="/heaven_en" element={<HeavenEn />} />
        <Route path="/hire_en" element={<HireEn />} />
        <Route path="/history_en" element={<HistoryEn />} />
        <Route path="/intro_en" element={<IntroEn />} />
        <Route path="/metaverse_en" element={<MetaverseEn />} />
        <Route path="/mozza_en" element={<MozzaEn />} />
        <Route path="/news_en" element={<NewsEn />} />
        <Route path="/notice_en" element={<NoticeEn />} />
        <Route path="/noticeDetail_en" element={<NoticeDetailEn />} />
        <Route path="/patent_en" element={<ParentEn />} />
        <Route path="/partner_en" element={<PartnerEn />} />
        <Route path="/sns_en" element={<SnsEn />} />
        {/* KO */}
        <Route path="/" element={<Homeko />} />
        <Route path="/introduce" element={<Introduce />} />
        <Route path="/history" element={<History />} />
        <Route path="/patent" element={<Patent />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/game" element={<Game />} />
        <Route path="/metaverse" element={<Metaverse />} />
        <Route path="/heaven" element={<Heaven />} />
        <Route path="/mozza" element={<Mozza />} />
        <Route path="/news" element={<News />} />
        <Route path="/notice" element={<Notice />} />
        <Route path="/noticedetail" element={<NoticeDetail />} />
        <Route path="/sns" element={<Sns />} />
        <Route path="/hire" element={<Hire />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </HashRouter>
  );
};

export default AppRouter;

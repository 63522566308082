import React, { useState } from 'react';
import Layout from '../../components/en/Layout';
import ContactModalEn from '../../components/en/ContactModalEn';
import { Link } from "react-router-dom";


const HeavenEn: React.FC = () => {
    const [isOpenContactEn, setIsOpenContactEn] = useState(false);
    const onclickModalEn = () => {
      setIsOpenContactEn(true);
    };
  return (
    <Layout>
      <div id="content">
            <section id="heavenMain" className="sub_main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">BUSINESS · Heaven</p>
                            <h3 className="wow fadeInDown" data-wow-delay="0.4s">Heaven, the standard<br />for
                                next-generation survival battle games</h3>
                            <p className="text wow fadeInDown" data-wow-delay="0.2s">Heaven is an Aeon Of Strife (AOS) game
                                inspired by global popular games such as DOTA/CHAOS.</p>
                        </div>
                    </div>
                    <div className="swiper mySwiper">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide"></div>
                            <div className="swiper-slide"></div>
                            <div className="swiper-slide"></div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="feature" className="sub_cont">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">Our feature</p>
                            <h4 className="wow fadeInDown" data-wow-delay="0.4s">Heaven, an Esports game</h4>
                            <p className="text wow fadeInDown" data-wow-delay="0.6s">Fast, precise control, and
                                instantaneous sense determine victory or defeat.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                            <div className="thumb">
                                <img src="images/heaven/feature1.png" alt="Esports competitions" />
                            </div>
                            <dl>
                                <dt>Esports competitions</dt>
                                <dd>Hosting competitions to revitalize Esports and encourage active participation of
                                    gamers</dd>
                            </dl>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="1.0s">
                            <div className="thumb">
                                <img src="images/heaven/feature2.png" alt="Full-fledged P2E GAME" />
                            </div>
                            <dl>
                                <dt>Full-fledged P2E GAME</dt>
                                <dd>– NFTization of individuals’ items to support trading in our marketplace</dd>
                            </dl>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInRight" data-wow-delay="1.2s">
                            <div className="thumb">
                                <img src="images/heaven/feature3.png" alt="Launch of global gaming platform Steam" />
                            </div>
                            <dl>
                                <dt>Launch of global gaming platform Steam</dt>
                                <dd>Plans to release games through Steam to advance into the global market and improve
                                    accessibility</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </section>

            <section id="special" className="sub_cont">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">Special factor</p>
                            <h4 className="wow fadeInDown" data-wow-delay="0.4s">Heaven's Differentiation Point</h4>
                        </div>
                    </div>
                    <div className="row fir">
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.2s">
                            <img src="images/heaven/special1.gif" alt="Heaven : Action" />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInRight" data-wow-delay="0.4s">
                            <dl>
                                <dt>Heaven : Action</dt>
                                <dd>Various motions are implemented to show the colorful actions of a character</dd>
                                <dd>Over 70 different motion expressions for each character</dd>
                                <dd>Expresses basic attack motion, special attack and combo attack motion for each
                                    character's action</dd>
                            </dl>
                        </div>
                    </div>
                    <div className="row sec">
                        <div className="col-lg-6 col-md-12 col-sm-12 right wow fadeInRight" data-wow-delay="0.6s">
                            <img src="images/heaven/special2.gif" alt="Heaven : Skill System" />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                            <dl>
                                <dt>Heaven : Skill System</dt>
                                <dd>A character-based game produced based on about 30 independent character traits</dd>
                                <dd>Each character has a story, and the story related to each character is reflected in
                                    the game and used for strategic character combination</dd>
                                <dd>Each character has a variety of skills suitable for 10 to 20 character traits</dd>
                            </dl>
                        </div>
                    </div>
                    <div className="row thi">
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="1.0s">
                            <img src="images/heaven/special3.gif" alt="Heaven : Map" />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInRight" data-wow-delay="1.2s">
                            <dl>
                                <dt>Heaven : Map</dt>
                                <dd>Plans so that users can enjoy a variety of strategic play, including strategy to
                                    induce large-scale and small-scale battles for each map</dd>
                                <dd>A map basically has 3 intrusion paths, and there are missions that allow strategic
                                    use of the game depending on the map</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </section>

            <section id="gallery" className="sub_cont">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">Gallery</p>
                            <h4 className="wow fadeInDown" data-wow-delay="0.4s">In-game image</h4>
                            <ul>
                                <li className="wow fadeInUp" data-wow-delay="0.6s">
                                    <img src="images/heaven/gallery1.png" alt="In-game image1" />
                                </li>
                                <li className="wow fadeInUp" data-wow-delay="0.7s">
                                    <img src="images/heaven/gallery2.png" alt="In-game image2" />
                                </li>
                                <li className="wow fadeInUp" data-wow-delay="0.8s">
                                    <img src="images/heaven/gallery3.png" alt="In-game image3" />
                                </li>
                                <li className="wow fadeInUp" data-wow-delay="0.9s">
                                    <img src="images/heaven/gallery4.png" alt="In-game image4" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section id="heavenContact" className="sub_contact">
                <div className="container">
                    <div className="row">
                        <h4 className="wow fadeInDown" data-wow-delay="0.4s">Want to learn more about Heaven?</h4>
                        {/* <button
                    title="contact"
                    className="btn_contact wow fadeInUp"
                    data-wow-delay="0.8s"
                    onClick={() => {
                    onclickModalEn();
                    }}
                    >
                    CONTACT US
                  </button> */}
                    <Link to="/contact_en" title="contact" className="btn_contact wow fadeInUp" data-wow-delay="0.8s">
                    CONTACT US</Link>
                    </div>
                </div>
            </section>
          </div>
          {isOpenContactEn ? (
              <ContactModalEn setIsOpenContactEn={setIsOpenContactEn} />
            ) : null}
    </Layout>
  );
};

export default HeavenEn;
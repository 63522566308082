import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/en/Layout";
import { get_boardList } from "../../utils/axios";
import { BoardListContent } from "../../utils/types";
import NewsDialogB from "../../components/NewsDialogB";
import ContactModalEn from "../../components/en/ContactModalEn";

const Home: React.FC = () => {
  const [NewsData, setNewsData] = useState<BoardListContent[] | []>([]);
  const [isOpenDialogB, setIsOpenDialogB] = useState(false);
  const [isOpenContactEn, setIsOpenContactEn] = useState(false);
  
  useEffect(() => {
    (async () => {
      const params = {
        page: 1,
        size: 6,
        boardIdx: 1,
      };
      try {
        const { data } = await get_boardList(params);
        setNewsData(data.List.content);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);
  const onclickModalEn = () => {
    setIsOpenContactEn(true);
  };
  const openDialog = (articleIdx: number, externalUrl: string) => {
    switch (articleIdx) {
      case 11:
        setIsOpenDialogB(true);
        break;
      default:
        window.open(externalUrl);
        break;
    }
  };

  return (
    <div>
      <Layout>
        <div id="content">
          <section id="main">
            <div className="container">
              <video title="matavideo" muted autoPlay loop>
                <source
                  src={process.env.PUBLIC_URL + "video/mainvideo.mp4"}
                  type="video/mp4"
                />
              </video>
              <div className="row">
                <div className="col-12">
                  <h3 className="wow fadeInDown" data-wow-delay="0.4s">
                    The Way to the Metaverse.
                    <br />
                    Meet us, Metarock
                  </h3>
                  <p className="wow fadeInDown" data-wow-delay="0.8s">
                    Metarock's vision is to make Metaverse and Esports platform
                    and to let people play,
                    <br />
                    connect each other, and do their economic activities.
                  </p>
                  {/* <button
                    title="contact"
                    className="btn_contact wow fadeInUp"
                    data-wow-delay="0.8s"
                    onClick={() => {
                    onclickModalEn();
                
                    }}
                    >
                    CONTACT US
                  </button> */}
                   <Link to={"/contact_en"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
                </div>
              </div>
            </div>
          </section>

          <section id="service">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p className="title wow fadeInDown" data-wow-delay="0.2s">
                    Our Services
                  </p>
                  <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                    METAROCK Service Model
                  </h4>
                </div>
              </div>
              <div className="row fir">
                <div
                  className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="images/service/service1.png"
                    alt="2WinChance, the world's best 'contest betting” platform'"
                  />
                </div>
                <div
                  className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft"
                  data-wow-delay="0.7s"
                >
                  <dl>
                    <dt>
                    2WinChance,  a comprehensive Gaming Platform Centered on 1<sup>st</sup> approved legal sports fantasy game in Korea

                    </dt>
                    <dd>
                      2WinChance is a comprehensive game platform which services various genres of P2E games such as
                      sports prediction games and board games based on WINNERPICK (the 1st fantasy sports game approved
                      by Korea **GRAC).
                      **Game Rating And Administration Committee

                    </dd>
                    <dd>
                      <Link
                        to="/game_en"
                        title="towinchance"
                        className="btn_more"
                      >
                        Learn more
                      </Link>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="row cross sec">
                <div
                  className="col-lg-6 col-md-12 col-sm-12 right wow fadeInRight"
                  data-wow-delay="0.8s"
                >
                  <img
                    src="images/test/service2.png"
                    alt="Metaverse-related business"
                  />
                </div>
                <div
                  className="col-lg-6 col-md-12 col-sm-12 wow fadeInRight"
                  data-wow-delay="0.9s"
                >
                  <dl>
                    <dt>The Worlds 1<sup>st</sup> Decentral, DotCube Protocol(DCP)</dt>
                    <dd>
                    The new Internet DotCube Protocol developed by Metarock, enables all users in the world to create their own Metaverse. Moreover, Authoring tools will be provided for free to help out building metaverse easier

                    </dd>
                    <dd>
                      <Link
                        to="/metaverse_en"
                        title="metaverse"
                        className="btn_more"
                      >
                        Learn more
                      </Link>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="row thi">
                <div
                  className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft"
                  data-wow-delay="1.0s"
                >
                  <img
                    src="images/service/service3.png"
                    alt="Heaven, the standard of next-generation survival battle games"
                  />
                </div>
                <div
                  className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft"
                  data-wow-delay="1.1s"
                >
                  <dl>
                    <dt>
                      Heaven, the standard of next-generation survival battle
                      games
                    </dt>
                    <dd>
                      Heaven is an Aeon Of Strife (AOS) game inspired by global
                      popular games such as DOTA/CHAOS.
                    </dd>
                    <dd>
                      <Link to="/heaven_en" title="heaven" className="btn_more">
                        Learn more
                      </Link>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="row cross for">
                <div
                  className="col-lg-6 col-md-12 col-sm-12 right wow fadeInRight"
                  data-wow-delay="1.2s"
                >
                  <img
                    src="images/service/service4.png"
                    alt="Mozza & Sali Full CG 3D Animation"
                  />
                </div>
                <div
                  className="col-lg-6 col-md-12 col-sm-12 wow fadeInRight"
                  data-wow-delay="1.3s"
                >
                  <dl>
                    <dt>Mozza &amp; Sali Full CG 3D Animation</dt>
                    <dd>
                      It is a slapstick comedy that Adulkid to Kidult can share
                      and enjoy. Transmedia contents in a multi-platform
                      environment are produced.
                    </dd>
                    <dd>
                      <Link
                        to="/mozza_en"
                        title="mozza_and_sali"
                        className="btn_more"
                      >
                        Learn more
                      </Link>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </section>

          <section id="mainNews">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p className="title wow fadeInDown" data-wow-delay="0.2s">
                    Our News
                  </p>
                  <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                    METAROCK NEWS
                  </h4>
                </div>
                {NewsData.map((data) => (
                  <div
                    key={data.articleIdx}
                    className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft"
                    data-wow-delay="0.65s"
                    onClick={() => {
                      openDialog(data.articleIdx, data.externalUrl);
                    }}
                  >
                    <div className="box">
                      <a target={`_blank`}>
                        <div className="thumb">
                          <img
                            src={data.thumbnailUrl}
                            alt="Main Title. METAROCK NEWS"
                          />
                        </div>
                        <div className="cont">
                          <p className="sub">
                            {data.externalAuthor}. {data.regDt}
                          </p>
                          <dl>
                            <dt>{data.articleSubject}</dt>
                            <dd>{data.articleContent}</dd>
                          </dl>
                        </div>
                      </a>
                    </div>
                  </div>
                ))}

                <div className="col-12 wow fadeInUp" data-wow-delay="0.9s">
                  <Link to="/news_en" className="btn_more" title="metarocknews">
                    More<i className="fal fa-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            {isOpenDialogB ? (
              <NewsDialogB setIsOpenDialogB={setIsOpenDialogB} />
            ) : null}
          </section>

          <section id="contact">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                    Do you have any questions about METAROCK?
                  </h4>
                  {/* <button
                    title="contact"
                    className="btn_contact wow fadeInUp"
                    data-wow-delay="0.8s"
                    onClick={() => {
                    onclickModalEn();
                    }}
                    >
                    CONTACT US
                  </button> */}
                     <Link to={"/contact_en"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
                </div>
              </div>
            </div>
        {isOpenContactEn ? (
              <ContactModalEn setIsOpenContactEn={setIsOpenContactEn} />
            ) : null}
          </section>
        </div>
      </Layout>
    </div>
  );
};

export default Home;

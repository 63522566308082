import React,{useState} from 'react';
import Layout from '../../components/en/Layout';
import ContactModalEn from '../../components/en/ContactModalEn'; 
import { Link } from "react-router-dom";
import 'swiper/css/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
import  SwiperCore,{ Autoplay,EffectFade } from 'swiper';

const MozzaEn: React.FC = () => {
    SwiperCore.use([Autoplay]);
    const [isOpenContactEn, setIsOpenContactEn] = useState(false);
    const onclickModalEn = () => {
      setIsOpenContactEn(true);
    };
  return (
    <Layout>
      <div id="content">
            <section id="mozzaMain" className="sub_main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">BUSINESS · Mozza &amp; Sali</p>
                            <h3 className="wow fadeInDown" data-wow-delay="0.4s">Mozza &amp; Sali<br />Full CG 3D Animation
                            </h3>
                        </div>
                    </div>
                  <Swiper
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              centeredSlides={true}
              modules={[Autoplay, EffectFade]}
              effect={"fade"}
              spaceBetween={50}
              slidesPerView={1}
              speed={3000}
            >
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
            </Swiper>
                  </div>
            </section>

            <section id="storyline" className="sub_cont">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">Storyline</p>
                            <h4 className="wow fadeInDown" data-wow-delay="0.4s">Mozza &amp; Sali's <br
                                    className="m" />Suspicious Buddy Animation</h4>
                            <p className="text wow fadeInDown" data-wow-delay="0.6s">Superhero's assistants and rivals
                                'Mozza &amp; Sali'. Their confrontation extends not only to the mission given by their
                                teacher, <br className="pc" />'Suuljjang (super ultra jjangjjangman)', but also to trivial
                                tasks such as cleaning and cooking. </p>
                            <img src="images/mozza/storyline.png" alt="Character Design" className="wow fadeInUp"
                                data-wow-delay="0.8s" />
                            <p className="text wow fadeInUp" data-wow-delay="1.0s">Character Design</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="mozza">
                <div className="container">
                    <div className="row fir">
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.2s">
                            <img src="images/mozza/mozza1.png" alt="Clear Vivid Tone" />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInRight" data-wow-delay="0.4s">
                            <p>Color Concept</p>
                            <dl>
                                <dt>Clear Vivid Tone</dt>
                                <dd>In order to bring out the fun of the mixed background and the personality of
                                    bouncing characters, the color is composed of Vivid Tone that is dynamic, intense,
                                    and Abundant.</dd>
                            </dl>
                        </div>
                    </div>
                    <div className="row sec">
                        <div className="col-lg-6 col-md-12 col-sm-12 right wow fadeInRight" data-wow-delay="0.6s">
                            <img src="images/mozza/mozza2.png"
                                alt="Vibrant video with a clear and refreshing feeling" />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                            <p>Tone &amp; Manner</p>
                            <dl>
                                <dt>Vibrant video with a clear and refreshing feeling</dt>
                                <dd>Tone that is a proper harmony of simple, clear and fresh colors and Low Key and
                                    Medium Key. Light and lively BGM, Sound, SFX suitable for motion</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </section>

            <section id="plan" className="sub_cont">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">Business plan</p>
                            <h4 className="wow fadeInDown" data-wow-delay="0.4s">Creating content that appeals to <br
                                    className="m" />both children and adults</h4>
                            <p className="text wow fadeInDown" data-wow-delay="0.6s">It is a slapstick comedy that everyone
                                from Adulkid to Kidult can share and enjoy together, <br className="pc" />and transmedia
                                content is produced in a multi-platform environment to maximize business expansion and
                                profits.</p>
                            <img src="images/mozza/plan.png"
                                alt="Creating content that appeals to both children and adults" className="wow fadeInUp"
                                data-wow-delay="0.8s" />
                            <p className="text wow fadeInUp" data-wow-delay="1.0s">Mozza &amp; Sali animation is based on
                                OSMU, Collaboration, and Co Work through product sales in various fields.<br
                                    className="pc" />High value-added content that can generate revenue, that is, in a
                                format that is easy to transmedia.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="mozzaContact" className="sub_contact">
                <div className="container">
                    <div className="row">
                        <h4 className="wow fadeInDown" data-wow-delay="0.4s">Want to join METAROCK?</h4>
                        {/* <button
                    title="contact"
                    className="btn_contact wow fadeInUp"
                    data-wow-delay="0.8s"
                    onClick={() => {
                    onclickModalEn();
                    }}
                    >
                    CONTACT US
                  </button> */}
                    <Link to={"/contact_en"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
                    </div>
                </div>
            </section>
          </div>
          {isOpenContactEn ? (
              <ContactModalEn setIsOpenContactEn={setIsOpenContactEn} />
            ) : null}
    </Layout>
  );
};

export default MozzaEn;
import React from 'react';
import Layout from '../../components/en/Layout';

const SnsEn: React.FC = () => {
  return (
    <Layout>
      <div id="content2">
            <section id="snsMain" className="sub_main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">PR CENTER · Social Media</p>
                            <h3 className="wow fadeInDown" data-wow-delay="0.4s">Guide to METAROCK Social Channel</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section id="sns">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <a onClick={() => window.open('https://twitter.com/metarock143', '_blank')}title="트위터" >
                    <img src={`${process.env.PUBLIC_URL}/images/sns/twitter.png`} alt="TWITTER" />
                    <p>TWITTER</p>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <a onClick={() => window.open('https://www.facebook.com/%EB%A9%94%ED%83%80%EB%A1%9D-102198669046522', '_blank')}
                    title="페이스북">
                    <img src={`${process.env.PUBLIC_URL}/images/sns/facebook.png`} alt="FACEBOOK" />
                    <p>FACEBOOK</p>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <a onClick={() => window.open('https://www.instagram.com/metarock_dcp/', '_blank')} title="인스타그램">
                    <img src={`${process.env.PUBLIC_URL}/images/sns/instagram.png`} alt="INSTAGRAM" />
                    <p>INSTAGRAM</p>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <a onClick={() => window.open('https://www.youtube.com/@Metarock_Official', '_blank')}
                    title="유튜브" >
                    <img src={`${process.env.PUBLIC_URL}/images/sns/youtube.png`} alt="YOUTUBE" />
                    <p>YOUTUBE</p>
                  </a>
                </div>
              </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="box">
                                <a href="#" className="btn_wait">
                                    <img src="images/sns/comingsoon.png" alt="Preparing" />
                                    <p>Preparing</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
  );
};

export default SnsEn;
import React from 'react'
import Footer from '../../components/ko/Footer'
import Header from '../../components/ko/Header'

const Patent = () => {
  return (
    <div className="wrap">
      <Header />
      <div id="content2">
        <section id="patentMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">METAROCK · 특허 / 인허가 자료</p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">메타록 특허 및 인허가 자료</h3>
              </div>
            </div>
          </div>
        </section>

        <section id="patent" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h4 className="wow fadeInDown" data-wow-delay="0.4s"> 특허 보유 현황</h4>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.6s">
                <div className="thumb">
                  <img src={`${process.env.PUBLIC_URL}/images/patent/patent1.png`} alt="인공지능 기반의 데이터 처리 시스템" />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                <div className="thumb">
                  <img src={`${process.env.PUBLIC_URL}/images/patent/patent2.png`} alt="이벤트 연동 교육서비스 제공 시스템" />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.0s">
                <div className="thumb">
                  <img src={`${process.env.PUBLIC_URL}/images/patent/patent3.png`} alt="맞춤형 정보를 제공하는 데이터 센터 시스템" />
                </div>
              </div>
              <div className="col-12 license">

                <h4 className="wow fadeInDown" data-wow-delay="0.4s">인허가 보유 현황</h4>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.1s">
                <div className="thumb">
                  <img src={`${process.env.PUBLIC_URL}/images/patent/patent4.png`} alt="부설연구소인정서" />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.2s">
                <div className="thumb">
                  <img src={`${process.env.PUBLIC_URL}/images/patent/patent5.png`} alt="게임배급업등록증" />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.3s">
                <div className="thumb">
                  <img src={`${process.env.PUBLIC_URL}/images/patent/patent6.png`} alt="통신판매업신고업" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <footer>
        <div className="container">
          <Footer />
        </div>
      </footer>
    </div>
  )
}

export default Patent
import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/en/Layout';


const IntroEn: React.FC = () => {
return (
<Layout>
    <div id="content">
        <section id="introMain" className="sub_main">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="title wow fadeInDown" data-wow-delay="0.2s">ABOUT METAROCK</p>
                        <h3 className="wow fadeInDown" data-wow-delay="0.4s">We are building a New World with
                            Metaverse<br />and Next Generation of Esports</h3>
                    </div>
                </div>
            </div>
        </section>

        <section id="intro">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.2s">
                        <h4>Metarock Provides a complete decentralized metaverse network service that break away from
                            traditional platform centricity. </h4>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInRight" data-wow-delay="0.4s">
                        <p>Metarock were established in order to achieve an implement of Metaverse throughout
                            development, partnership and cooperation. Our entire team are expertised and has been
                            working for a long time in tech-development industry (Games, VR, AI, Entertainment and
                            E-Commerce so on). Metarock will provide you a satisfied experience by providing our own
                            Metaverse network service ‘DotCube Protocol' to build your own Metaverse, available for any
                            types of business (NFT, P2E Game, Realistic content-based business etc).
                        </p>
                    </div>
                    <div className="ceo wow fadeInRight" data-wow-delay="0.6s"><span>Jo Young Wook</span>, CEO of
                        METAROCK Inc.</div>
                </div>
            </div>
        </section>

        <section id="business" className="sub_cont">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="title wow fadeInDown" data-wow-delay="0.2s">METAROCK'S BUSINESS</p>
                        <h4 className="wow fadeInDown" data-wow-delay="0.4s">Service For You</h4>
                        <p className="text wow fadeInDown" data-wow-delay="0.6s">Experience the game above the game and
                            the platform that makes the platform.</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                        <div className="box">
                            <Link to="/game_en" title="2WinChance">
                            <div className="thumb">
                                <img src="images/business/business1.png" alt="2WinChance" />
                            </div>
                            <div className="cont">
                                <h5>2WinChance</h5>
                                <p>Learn more <i className="far fa-chevron-right"></i></p>
                            </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.9s">
                        <div className="box">
                            <Link to="/metaverse_en" title="metaverse">
                            <div className="thumb">
                                <img src="images/business/business2.png" alt="dotcubeprotocol" />
                            </div>
                            <div className="cont">
                                <h5>DotCube Protocol</h5>
                                <p>Learn more <i className="far fa-chevron-right"></i></p>
                            </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.0s">
                        <div className="box">
                            <Link to="/heaven_en" title="heaven">
                            <div className="thumb">
                                <img src="images/business/business3.png" alt="Heaven" />
                            </div>
                            <div className="cont">
                                <h5>Heaven</h5>
                                <p>Learn more <i className="far fa-chevron-right"></i></p>
                            </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.1s">
                        <div className="box">
                            <Link to="/mozza_en" title="mozza_and_sali">
                            <div className="thumb">
                                <img src="images/business/business4.png" alt="Mozza & Sali" />
                            </div>
                            <div className="cont">
                                <h5>Mozza &amp; Sali</h5>
                                <p>Learn more <i className="far fa-chevron-right"></i></p>
                            </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="organization" className="sub_cont">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="title wow fadeInDown" data-wow-delay="0.2s">Organization Chart</p>
                        <h4 className="wow fadeInDown" data-wow-delay="0.4s">METAROCK Organization Chart</h4>
                        <img src="images/organization/organization_en.png" alt="METAROCK Organization Chart"
                            className="pc_img wow fadeInUp" data-wow-delay="0.8s" />
                        <img src="images/organization/organization_m_en.png" alt="METAROCK Organization Chart"
                            className="m_img wow fadeInUp" data-wow-delay="0.8s" />
                    </div>
                </div>
            </div>
        </section>

        <section id="introContact" className="sub_contact">
            <div className="container">
                <div className="row">
                    <h4 className="wow fadeInDown" data-wow-delay="0.4s">Do you have any questions about METAROCK?</h4>
                    <Link to="/contact_en" title="contact" className="btn_contact wow fadeInUp" data-wow-delay="0.8s">
                    CONTACT US</Link>
                </div>
            </div>
        </section>
    </div>
</Layout>
);
};

export default IntroEn;
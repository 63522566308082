import React from "react";

const TopButton: React.FC = () => {
  const scrollTop = () => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  };

  return (
    <button onClick={scrollTop} title="top" className="btn_top">
      <i className="far fa-chevron-double-up"></i>
    </button>
  );
};

export default TopButton;

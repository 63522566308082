import React from 'react'
import Footer from '../../components/ko/Footer'
import Header from '../../components/ko/Header'

const History = () => {
  return (
    <div className="wrap">
      <Header />
      <div id="content2">
        <section id="historyMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">METAROCK · History</p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">메타록 History</h3>
              </div>
            </div>
          </div>
        </section>

        <section id="history" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">History</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">메타록 연혁</h4>
                <ul>
                        <li className="wow fadeInDown" data-wow-delay="0.5s">
                          <span className="date">2023.06</span>
                          <p>누리플렉스홀딩스와 ‘스포츠 메타버스 존’ 구축을 위한 양해각서 체결</p>
                          <p>Liaison Advisory Group과 美힙합레이블 Empire Distribution ‘Hip-hop Metaverse’ 구축 MoU 체결</p>
                        </li>
                        <li className="wow fadeInDown" data-wow-delay="0.6s">
                          <span className="date">2023.05</span>
                          <p>㈜아톰홀딩스와 “아톰 메타버스 개발 및 서비스에 대한 공동 사업”을 위한 양해각서 체결</p>
                        </li>
                        <li className="wow fadeInDown" data-wow-delay="0.7s">
                          <span className="date">2023.03</span>
                          <p>판타지 스포츠 승부 예측게임 WINNERPICK 출시</p>
                          <p>EDAI와 메타버스내 광고 솔루션 계약 체결</p>
                          <p>한국자율공정거래연합, DCP 공식 메타버스 선정</p>
                          <p>세계최초 탈중앙화 메타버스 프로토콜 DCP(DotCube Protocol) 소프트런칭</p>
                        </li>
                        <li className="wow fadeInDown" data-wow-delay="0.8s">
                          <span className="date">2023.02</span>
                          <p>텐센트 클라우드와 DCP서비스에 대한 글로벌 서비스 추진 및 신규 비즈니스 모델 개발 업무 협약 체결</p>
                          <p>스포츠 승부 예측 게임 IMPICK 출시</p>
                        </li>
                        <li className="wow fadeInDown" data-wow-delay="0.9s">
                        <span className="date">2023.01</span>
                          <p>
                          EDAI와 “메타버스 사업 공동 추진 업무 협약” 체결
                          </p>
                          <p>
                          GloHEAD Co.Ltd와 “메타버스 아시아 권역 성형 정보 서비스 공동 사업 추진 계약” 체결
                          </p>
                        </li>
                        <li className="wow fadeInDown" data-wow-delay="1.0s">
                        <span className="date date_2022">2022.11</span>
                          <p>
                          주식회사 파로스랩스와 "CRATOS METAVERSE 개발 협약서"체결
                          </p>
                          <p>
                          블리츠투자개발 주식회사와 "300억 투자 약정서"체결
                          </p>
                          <p>
                          주식회사 세븐빌리언 랩스, "스틸덕 프로젝트 애니메이션, 게임 및 메타버스 개발 업무협약" 체결
                          </p>
                        </li>
                        <li className="wow fadeInDown" data-wow-delay="1.1s">
                        <span className="date">2022.10</span>
                          <p>
                          주식회사 토마토 체인과 "메타버스 기반 플랫폼 사업 협력을 위한 업무 협약"체결
                          </p>
                          <p>
                          주식회사 TVM과 "스페인 프로축구 라리가 메타버스 구축을 위한 공동개발 양해각서"체결
                          </p>
                          <p>
                           주식회사 TuneGem과 "K-pop 오디션 메타버스 구축을 위한 업무 협약"체결
                          </p>
                        </li>
                        <li className="wow fadeInDown" data-wow-delay="1.2s">
                        <span className="date">2022.09</span>
                          <p>
                           MABC PTE LTD와 "메타버스 기반 NFT마켓 플레이스 및 Wallet 구축 계약"체결
                          </p>
                        </li>
      
                        <li className="wow fadeInDown" data-wow-delay="1.3s">
                        <span className="date ">2022.08</span>
                          <p>
                        사단법인 한국자동차협회의 "교통 안전 교육 메타버스 구축" MoA 체결
                          </p>
                        </li>
                        <li className="wow fadeInDown" data-wow-delay="1.4s">
                        <span className="date">2022.07</span>
                          <p>
                          AIM뮤직과 "AMIY Moon MetaHuman & Metaverse 구축에 관한 업무 협약"체결
                          </p>
                        </li>
      
                        <li className="wow fadeInDown" data-wow-delay="1.5s">
                        <span className="date">2022.06</span>
                          <p>주식회사 리더스기술투자와 “투윈챈스 및 P2E 게임 투자에 관한 업무 협약” 체결</p>
                          <p>MABC PTELTD와 "MABC Art Metaverse 구축 계약" 체결 및 1단계 구축 사업 착수 </p>
                        </li>
      
                        <li className="wow fadeInDown" data-wow-delay="1.6s">
                        <span className="date">2022.04</span>
                          <p>AIM뮤직과 "AMIY Moon Metaverse 공동 개발에 관한 엄무 협약"체결</p>
                          <p>주식회사 원에이넷과 "보드게임 등 게임 공급에 관련 협력 업무 협약"체결</p>
                          <p>주식회사 몽몽이와 "반려동물 메타버스 구현에 관련 협력 업무 협약"체결</p>
                        </li>
                        <li className="wow fadeInDown" data-wow-delay="1.7s">
                          <span className="date">2022.03</span>
                          <p>사명 변경 ‘(주)메타록’</p>
                          <p>대표이사 서해영 취임 </p>
                          <p>사업목적 추가
                            (게임서비스, 소프트웨어 개발 및 공급 등)
                          </p>
                        </li>
                        <li className="wow fadeInDown" data-wow-delay="1.8s">
                          <span className="date">2019.03</span>
                          <p>
                          주식회사 '지텍아이' 설립
                          </p>
                        </li>
                      </ul>
              </div>
            </div>
          </div>
        </section>

      </div>

      <footer>
        <div className="container">
          <Footer />
        </div>
      </footer>
    </div>
  )
}

export default History
import React, { KeyboardEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../components/en/Layout";
import { get_boardList } from "../../../utils/axios";
import { BoardListParams } from "../../../utils/types";
import ContactModalEn from "../../../components/en/ContactModalEn";

const NewsEn: React.FC = () => {
  const [isOpenContactEn, setIsOpenContactEn] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [NewsData, setNewsData] = useState([]);
  const [searchType, setSearchType] = useState<string>("articleSubject");
  const [searchText, setSearchText] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleKeyPressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      setPage(1);
      search();
    }
  };

  const searchBtn = () => {
    setPage(1);
    search();
  };

  const changeSearchType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchType(e.target.value);
  };

  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const search = async () => {
    let params: BoardListParams = {
      page: page,
      size: 10,
      boardIdx: 2,
      lang: "en",
    };
    if (searchText.length > 0) {
      if (searchType === "articleContent") {
        params = { ...params, articleContent: searchText };
      }
      if (searchType === "articleSubject") {
        params = { ...params, articleSubject: searchText };
      }
    }
    try {
      const { data } = await get_boardList(params);
      setTotalPage(data.totalPages);
      setNewsData(data.List.content);
    } catch (err) {
      console.error(err);
    }
  };

  const pagination = () => {
    const result = [];
    for (let i = 1; i <= totalPage; i++) {
      result.push(
        <li
          className="page-item"
          onClick={() => setPage(i)}
          style={{ cursor: "pointer" }}
          key={i}
        >
          {page === i ? (
            <span className="page-link" style={{ fontWeight: "bold" }}>
              {i}
            </span>
          ) : (
            <span className="page-link" style={{ color: "gray" }}>
              {i}
            </span>
          )}
        </li>
      );
    }
    return result;
  };
  const onclickModalEn = () => {
    setIsOpenContactEn(true);
  };

  useEffect(() => {
    search();
    navigate({ pathname: "", search: `?p=${page}` });
  }, [page]);

  useEffect(() => {
    if (location.search.split("p=")[1]) {
      const page = location.search.split("p=")[1];
      setPage(Number(page));
    } else {
      navigate({ pathname: "", search: "?p=1" });
      setPage(1);
    }
  }, [location]);

  return (
    <Layout>
      <div id="content">
        <section id="noticeMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">
                  PR Center · Notice
                </p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">
                  METAROCK NOTICE
                </h3>
              </div>
            </div>
          </div>
        </section>

        <section id="noticeCont">
          <div className="container">
            <div className="col-lg-12 col-md-12 col-sm-12 wow fadeInRight notice_search_form">
              <fieldset>
                <legend style={{ display: "none" }}>NoticeSearch</legend>
                <p className="search-select-wrap">
                  <select name="field" onChange={changeSearchType}>
                    <option value="articleSubject">Title</option>
                    <option value="articleContent">Contents</option>
                  </select>
                </p>
                <p className="search-input-wrap">
                  <input
                    type="text"
                    className="searchInput"
                    onKeyUp={handleKeyPressEnter}
                    onChange={handleSearchText}
                  />
                  <button
                    onClick={searchBtn}
                    type="button"
                    className="searchBtn"
                  >
                    Search
                  </button>
                </p>
              </fieldset>
            </div>
            <ul className="noticeListHeader pc">
              <li className="title">Title</li>
              <li className="date">Date</li>
            </ul>
            {NewsData.length === 0 ? (
              <div className="List_none">No search results found.</div>
            ) : null}
            {NewsData?.map(({ articleSubject, regDt, articleIdx }) => (
              <div
                key={articleIdx}
                className="col-lg-12 col-md-12 col-sm-12 wow fadeIn"
                data-wow-delay="0.6s"
              >
                <div className="NoticeList">
                  <Link
                    to={`/noticeDetail_en?t=${articleIdx}`}
                    title="공지사항"
                    rel="noreferrer"
                  >
                    <div className="cont">
                      <dl className="noticeListbody">
                        <dt className="title">{articleSubject}</dt>
                        <dd className="date">{regDt}</dd>
                      </dl>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <ul
            className="pagination pagination_wrap"
            style={{ cursor: "pointer" }}
          >
            {pagination()}
          </ul>
        </section>

        <section id="noticeContact" className="sub_contact">
          <div className="container">
            <div className="row">
              <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                Do you have any questions about METAROCK?
              </h4>
              {/* <button
                    title="contact"
                    className="btn_contact wow fadeInUp"
                    data-wow-delay="0.8s"
                    onClick={() => {
                    onclickModalEn();
                    }}
                    >
                    CONTACT US
              </button> */}
              <Link
                to="/contact_en"
                title="contact"
                className="btn_contact wow fadeInUp"
                data-wow-delay="0\.8s"
              >
                CONTACT US
              </Link>
            </div>
          </div>
        </section>
      </div>
      {isOpenContactEn ? (
              <ContactModalEn setIsOpenContactEn={setIsOpenContactEn} />
            ) : null}
    </Layout>
  );
};

export default NewsEn;

import React ,{useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, EffectFade} from "swiper";
import 'swiper/css/autoplay';
import "swiper/css/effect-fade";
import Header from '../../components/ko/Header';
import Footer from '../../components/ko/Footer';
import ContactModal from '../../components/ko/ContactModal';
import { Link } from 'react-router-dom';

const Metaverse = () => {
  const [isOpenContact, setIsOpenContact] = useState(false);
  const onclickModal = () =>{
    setIsOpenContact(true)
  }
  return (
    <div className="wrap">
      <Header />
      <div id="content">
        <section id="metaverseMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">BUSINESS · DotCube Protocol</p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">DotCube Protocol를 통해<br />새로운 세상으로 인도합니다.</h3>
                <p className="text wow fadeInDown" data-wow-delay="0.2s">METAROCK DotCube Protocol</p>
              </div>
            </div>
            <Swiper
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              centeredSlides={true}
              modules={[Autoplay, EffectFade]}
              effect={"fade"}
              spaceBetween={50}
              slidesPerView={1}
              speed={3000}
            >
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
            </Swiper>
          </div>
        </section>

        <section id="metaFeature" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">Metaverse is future</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">현재 우리 사회는 어디로 가고 있나?</h4>
                <p className="text wow fadeInDown" data-wow-delay="0.6s">세상은 늘 갑자기 변합니다. 그래서 우리는 Covid-19 이전, 현재
                  그리고 이후에 대한 준비가 필요합니다.</p>
                <div className="line"></div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                <dl>
                  <dt>컨텍트<br />Contact</dt>
                  <dd>사람과 사람이 직접 대면하여 서비스가<br />이루어지던 방식<br />(Last Years)</dd>
                </dl>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.9s">
                <dl>
                  <dt>언텍트<br />Un-tact</dt>
                  <dd> 코로나로 인해 사람과 사람이 접촉하지<br />않고 비대면 방식으로 변화<br />(Next Few Years)</dd>
                </dl>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.0s">
                <dl>
                  <dt>온텍트<br />On-tact</dt>
                  <dd>'언텍트(Untact)'에 온라인을 통한<br />외부와의 '연결(On)'을 더한 개념<br />(Next Few Years)</dd>
                </dl>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.1s">
                <dl>
                  <dt>메타버스<br />Metaverse</dt>
                  <dd>Metaverse is future of society<br />새로운 연결, 소통, 협력이 가능한 세상<br />(After Corona19)
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </section>

        <section id="vision" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.7s">Metaverse Network based on DotCube Protocol </p>
                <h4 className="wow fadeInDown" data-wow-delay="0.8s">DotCube Protocol 기반 Metaverse Network</h4>
                <img src={`${process.env.PUBLIC_URL}/images/metaverse/ecosystem.png`} alt="DotCube Protocol 기반 Metaverse Network" className="pc_img wow fadeInUp"
                  data-wow-delay="0.6s" />
                <img src={`${process.env.PUBLIC_URL}/images/metaverse/ecosystem_m.png`} alt="DotCube Protocol 기반 Metaverse Network"
                  className="m_img wow fadeInUp" data-wow-delay="0.6s" />
                <p className="text wow fadeInUp" data-wow-delay="0.8s">메타록은 다양한 산업 분야에 메타버스를 접목시켜 새로운 가치를 창출하고자
                  합니다.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="methodology" className="sub_cont">
          <div className="container">
            <div className="row" style={{justifyContent: 'center'}}>
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">Direction of DotCube Protocol</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">DotCube Protocol의 방향성</h4>
              </div>
              <div className='dotcube_direction'>
                <ul className='dotcube1 col-lg-3 col-md-6 col-sm-12 wow fadeInUp' data-wow-delay="0.6s">
                  <li className='tit fadeInUp'>기존 메타버스 상황</li>
                  <li>플랫폼의 공간을 중심으로 한<br className='pc'></br>서비스 사용자 네트워크</li>
                  <li>대형 플랫폼의 메가 서비스화 <span style={{marginTop:"-44px"}}>&#40;독점적 중앙화 진행 &#41;
                  </span> </li>
                  <li>독립 서비스의 플랫폼 지향 및 고립  <span style={{marginTop:"-20px"}}>
                  &#40;자체 서비스의 진입 장벽에 따른 소규모 서비스의 플랫폼화 및 마케팅 제약으로 인한 고립화 &#41;
                  </span></li>
                  <li>플랫폼 제약으로 인한 사용자 경험 제한
                    <span style={{marginTop:"-44px"}}>
                    &#40;제한된 기술 규격 및 공간 다양성 제약 &#41;
                    </span>
                  </li>
                  <li>중앙화된 플랫폼 서비스에 의한<br className='pc'></br> 비즈니스 확장의 한계
                  </li>
                </ul>
                <span className='arrow'><img src={`${process.env.PUBLIC_URL}/images/metaverse/caret-right-solid2.svg`} alt="right-arrow" className="wow arrow_led" style={{animationName:"arrow_led", animationIterationCount:"infinite", animationDuration:"0.9s"}} data-wow-delay="0.6s"></img></span>
                <ul className='dotcube2 col-lg-3 col-md-6 col-sm-12 wow fadeInUp' data-wow-delay="0.8s">
                  <li className='tit fadeInUp'>메타버스 서비스 지향점</li>
                  <li>확장된 사용자 경험을 위한 서비스 구조</li>
                  <li>Wed3.0의 대표적 서비스 프레임으로<br className='pc'></br> 탈중앙화 기반 서비스</li>
                  <li>각 개별 서비스간의 네트워킹을 통한<br className='pc'></br> 소통과 공유 필요</li>
                  <li>5G 기반 네트워크, ComputingPower, <br className='pc'></br>사용자 하드웨어의 다양화 등 기반
                    인프라를 활용한 강회된 사용자 경험
                  </li>
                  <li>블록체인 등 웹 3.0 서비스와 결합된<br className='pc'></br> 비즈니스 생태계를 담을 수 있는 비즈니스플랫폼으로서의
                    메타버스 서비스
                  </li>
                </ul>
                <div className='arrow_g' style={{alignSelf:"center"}}>
                <span className='arrow'><img src={`${process.env.PUBLIC_URL}/images/metaverse/caret-right-solid2.svg`} alt="right-arrow" className="wow arrow_led" data-wow-delay="0.9s"></img></span>
                <span className='arrow'><img src={`${process.env.PUBLIC_URL}/images/metaverse/caret-right-solid.svg`} alt="right-arrow" className="wow arrow_led" data-wow-delay="1.5s"></img></span>
                </div>
                <ul className='dotcube3 col-lg-3 col-md-6 col-sm-12 wow fadeInUp' data-wow-delay="1.0s">
                  <li className='tit fadeInUp' data-wow-delay="1.2s">DotCube의 방향성</li>
                  <li>사용자의 개별 경험을 중심으로<br className='pc'></br>다양화되고 유기적으로 연계되어 있는
                    확장된 메타버스 세계 구현
                  </li>
                  <li>탈중앙화 기반의<br className='pc'></br>메타버스 네트워크 서비스 구현</li>
                  <li>개별 비즈니스의 특성을 가진 서비스간의 네트워크 및 사용자 네트워크를 연결할 수 있는 기술 기반 제공</li>
                  <li>서비스의 확장성을 고려한<br className='pc'></br>Technical Platform 구성
                    <span style={{marginTop:"-20px"}}>
                    &#40; 사용자 몰입을 강화할 수 있는 기술기반 확보 &#41; 
                    </span>
                  </li>
                  <li>개별서비스의 독립성을 보장함과 동시에 네트워크내에서 다양한 자원을 공유할 수 있는 개방형 구조 수립
                  </li>
                 </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="status" className="sub_cont">
          <div className="container">
            <div className="row" style={{justifyContent:'center'}}>
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">Development status</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">현재 개발 진행 중인 <br className="m" />메타버스 서비스</h4>
              </div>
              <div className="status_body row">
                <Swiper
                  autoplay={{
                delay: 4000,
                disableOnInteraction: false,
                  }}
                  slidesPerView={6}
                 modules={[Autoplay]}
                  spaceBetween={15}
                  loop={true}
                  speed={4000}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 15,
                    },
                    1024: {
                      slidesPerView: 3,
                    }
                  }}
                >

                <SwiperSlide>
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status1.png`} alt="status1" className="wow fadeInUp"></img>
                     </div>
                        <div className="back">
                          <div className="text">
                          <h1>TVM</h1>
                          <p>La Liga Metaverse</p>
                          </div>
                     </div>
                   </div>
                </div>                    
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status4.jpg`} alt="status4" className="wow fadeInUp"></img>
                     </div>
                    <div className="back">
                    <div className="text">
                          <h1>TuneGem</h1>
                          <p>K-pop 오디션 메타버스</p>
                          </div>
                     </div>
                   </div>
                    </div>     
                    </SwiperSlide>    
                  <SwiperSlide>
                  <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status2.jpg`} alt="status2" className="wow fadeInUp"></img>
                     </div>
                        <div className="back">
                          <div className="text">
                          <h1>MABC</h1>
                          <p>MABC Art Metaverse</p>
                          </div>
                     </div>
                   </div>
                </div>                    
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status5.jpg`} alt="status5" className="wow fadeInUp"></img>
                     </div>
                    <div className="back">
                    <div className="text">
                          <h1>파로스랩스</h1>
                          <p>메타버스 기반 서베이 서비스</p>
                          </div>
                     </div>
                   </div>
                    </div>     
                  </SwiperSlide>
                  <SwiperSlide>
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status3.jpg`} alt="status3" className="wow fadeInUp"></img>
                     </div>
                        <div className="back">
                          <div className="text">
                            <h1>AIM뮤직</h1>
                            <p>AIMY Moon Metaverse</p>
                          </div>
                     </div>
                   </div>
                </div> 
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status9.jpg`} alt="status9" className="wow fadeInUp"></img>
                     </div>
                    <div className="back">
                    <div className="text">
                            <h1>몽몽이</h1>
                            <p>반려동물 메타버스</p>
                          </div>
                     </div>
                   </div>      
                </div>
                  </SwiperSlide>
                  <SwiperSlide>
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status7.jpg`} alt="status7" className="wow fadeInUp"></img>
                     </div>
                    <div className="back">
                    <div className="text">
                            <h1>세븐빌리언랩스</h1>
                            <p>Still duck Universe club (SDUC)</p>
                          </div>
                     </div>
                   </div>
                </div>                    
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status8.jpg`} alt="status8" className="wow fadeInUp"></img>
                     </div>
                    <div className="back">
                    <div className="text">
                            <h1>토마토체인</h1>
                            <p>메타버스 기반 플랫폼 서비스</p>
                          </div>
                     </div>
                   </div>
                </div>                                                        
              </SwiperSlide>
              <SwiperSlide>             
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status.jpg`} alt="status" className="wow fadeInUp"></img>
                     </div>
                        <div className="back">
                          <div className="text">
                            <h1>Preparing..</h1>
                            <p></p>
                          </div>
                     </div>
                   </div>
                </div>
                {/*             
                <div className="filp_card">
                  <div className="box">
                    <div className="front">
                    <img src={`${process.env.PUBLIC_URL}/images/metaverse/status6.jpg`} alt="status6" className="wow fadeInUp"></img>
                     </div>
                    <div className="back">
                    <div className="text">
                            <h1>피코앤테크</h1>
                            <p>빅데이터 기반 노인 뇌 건강 관리 메타버스</p>
                          </div>
                     </div>
                   </div>
                </div>                    
                */}                                    
              </SwiperSlide>
                
              </Swiper>
              </div>
            </div>
          </div>
        </section>

        <section id="metaverseContact" className="sub_contact">
          <div className="container">
            <div className="row">
              <h4 className="wow fadeInDown" data-wow-delay="0.4s">Metarock 과 함께 하고 싶으신가요?</h4>
              {/* <button
    title="contact" 
    className="btn_contact wow fadeInUp"
    data-wow-delay="0.8s"
    onClick={() => {
      onclickModal()
    }}
  >
    CONTACT US
      </button> */}
              <Link to={"/contact"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
            </div>
          </div>
        </section>
      </div>
      {isOpenContact ? (
              <ContactModal setIsOpenContact={setIsOpenContact} />
            ) : null}
      <footer>
        <div className="container">
          <Footer />
        </div>
      </footer>
    </div>
  )
}

export default Metaverse
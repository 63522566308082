import React ,{useState}from 'react';
import { Link } from 'react-router-dom';
import ContactModalEn from './ContactModalEn';

const Footer = ({ contect }: { contect?: boolean }) => {
    const [isOpenContactEn, setIsOpenContactEn] = useState(false);
    const onclickModalEn = () => {
        setIsOpenContactEn(true);
      };
    return (
        
        <footer>
            {isOpenContactEn ? (
              <ContactModalEn setIsOpenContactEn={setIsOpenContactEn} />
            ) : null}
       
            {
                contect ?
                    <div className='container'>

                        <div className="row footer-contact">
                            <div className="col-lg-8 col-md-8 col-sm-12 wow fadeInLeft" data-wow-delay="0.2s">
                                <img src="images/logo/logo_footer.png" alt="METAROCK" />
                                <p>Do you have any questions about METAROCK?</p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInRight" data-wow-delay="0.3s">
                            {/* <button
                    title="contact"
                    className="btn_contact wow fadeInUp"
                    data-wow-delay="0.8s"
                    onClick={() => {
                    onclickModalEn();
                    }}
                    >
                    CONTACT US
                  </button> */}
                                <a href="mailto:biz@metarock.co.kr?subject=CONTACT US" title="cantactus" className="btn_contact">CONTACT US</a>
                            </div>
                        </div>
                    </div>
                    : null
            }
            <div className="col-12">
                <div className="container">

                    <ul className="fnb clear">
                        <li>
                            <dl>
                                <dt className="wow fadeInLeft" data-wow-delay="0.4s">METAROCK</dt>
                                <dd className="wow fadeInLeft" data-wow-delay="0.45s"><Link to="/intro_en"
                                    title="about">About Us</Link></dd>
                                <dd className="wow fadeInLeft" data-wow-delay="0.5s"><Link to="/history_en"
                                    title="history">history</Link></dd>
                                <dd className="wow fadeInLeft" data-wow-delay="0.55s"><Link to="/patent_en"
                                    title="patent and license">Patent and License</Link></dd>
                                <dd className="wow fadeInLeft" data-wow-delay="0.6s"><Link to="/partner_en"
                                    title="Partnership">Partners</Link></dd>

                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt className="wow fadeInLeft" data-wow-delay="0.65s">BUSINESS</dt>
                                <dd className="wow fadeInLeft" data-wow-delay="0.7s"><Link to="/metaverse_en"
                                    title="dotcubeprotocol">DotCube Protocol</Link></dd>
                                <dd className="wow fadeInLeft" data-wow-delay="0.75s"><Link to="/game_en"
                                    title="2winchance">2WinChance</Link></dd>
                                <dd className="wow fadeInLeft" data-wow-delay="0.8s"><Link to="/heaven_en"
                                    title="heaven">Heaven</Link></dd>
                                <dd className="wow fadeInLeft" data-wow-delay="0.85s"><Link to="/mozza_en"
                                    title="mozza_and_sail">Mozza &amp; Sali</Link></dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt className="wow fadeInLeft" data-wow-delay="0.9s">PR CENTER</dt>
                                <dd className="wow fadeInLeft" data-wow-delay="0.95s"><Link to="/notice_en"
                                    title="news">Metarock Notice</Link></dd>
                                <dd className="wow fadeInLeft" data-wow-delay="0.95s"><Link to="/news_en"
                                    title="news">Metarock News</Link></dd>
                                <dd className="wow fadeInLeft" data-wow-delay="1.0s"><Link to="/sns_en"
                                    title="sns">Social Media</Link></dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt className="wow fadeInLeft" data-wow-delay="1.05s">CAREERS</dt>
                                <dd className="wow fadeInLeft" data-wow-delay="1.1s"><Link to="/hire_en"
                                    title="recruitment">Recruitment</Link></dd>
                                <dd className="wow fadeInLeft" data-wow-delay="1.15s"><Link to="/faq_en"
                                    title="hire_faq">Recruitment FAQ</Link></dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt className="wow fadeInLeft" data-wow-delay="1.2s"><Link
                                    to="/contact_en">CONTACT</Link></dt>
                            </dl>
                        </li>
                    </ul>
                    <p className="copy wow fadeInUp" data-wow-delay="1.25s">COPYRIGHT &copy; 2022. Metarock Inc. All Right Reserved.</p>
                </div>
            </div>

        </footer>
    );
};

export default Footer;
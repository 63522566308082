import axios from "axios";
import { articleDetailParams, BoardListParams } from "./types";

const baseURL = `${process.env.REACT_APP_BASE_URL}api/v1`;

export const get_boardCategory = () =>
  axios({ url: `${baseURL}/GET_BoardCategory`, method: "post" });

export const get_boardList = (params: BoardListParams) =>
  axios({ url: `${baseURL}/GET_BoardList`, method: "post", params });

export const get_articleDetail = (params: articleDetailParams) =>
  axios({ url: `${baseURL}/GET_ArticleDetail`, method: "post", params });

export const uset_boardReadCntIncrease = (idx: string) =>
  axios({
    url: `${baseURL}/USET_BoardReadCntIncrease`,
    method: "post",
    params: { articleIdx: idx },
  });

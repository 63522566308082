import { Dispatch, SetStateAction } from "react";

const NewsDialogB = ({
  setIsOpenDialogB,
}: {
  setIsOpenDialogB: Dispatch<SetStateAction<boolean>>;
}) => {
  const closeDialog = () => {
    setIsOpenDialogB(false);
  };
  const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  return (
    <div className="article" onClick={closeDialog}>
      <div className="article_wrap container" onClick={stopPropagation}>
        <div className="dialog_header">
          <h1>METAROCK News</h1>
          <span className="material-icons" onClick={closeDialog}>
            clear
          </span>
        </div>
        <div className="dialog_body_wrap">
          <h1 className="dialog_tit">
            메타록, TVM과 라리가 메타버스 구축 합의
          </h1>
          <p className="days">
            <span>METAROCKNews</span>
            <span>2022-10-28</span>
          </p>
          <div className="article_img row">
            <img
              src={`${process.env.PUBLIC_URL}/images/news/art3.jpg`}
              alt="디지털조선"
            />
          </div>
          <div className="article_body row">
            <p>
              주식회사 메타록(대표 서해영)이 10월 28일 주식회사 TVM(공동대표
              유성민, 이슬)과 스페인 라리가 메타버스 구축을 위한 공동개발 2차
              양해각서를 체결했다고 밝혔다. TVM은 메타버스 플랫폼 기업으로
              8억5천만 글로벌 축구 팬덤을 지닌 글로벌 스포츠
              메가IP(지식재산권)를 보유한 라리가(LaLiga)의 공식 파트너사로 작년
              5월 한국기업 최초로 메타버스 파트너십을 맺었다.
            </p>
            <p>
              메타록은 세계 최초 탈중앙서버의 메타버스 프로토콜을 개발한
              업체이다. 지난 코엑스 메타버스 전시회에서 이름을 알린 후
              메타버스의 새로운 혁명을 불러일으킬 것으로 기대하고 있다. 메타록의
              닷큐브프로토콜은 기존의 사업자 중심의 서비스 플랫폼을 사용자
              중심의 서비스 플랫폼으로 구축하는 메타버스의 패러다임을 바꾸는
              획기적인 프로토콜 기반의 플랫폼이다.
            </p>
            <p>
              글로벌 스포츠 메타버스 시장 1등을 목표로 하고 있는 TVM과 메타록이
              손을 잡으면서 순수한 국내기술로 새로운 메타버스 시장을 선도해
              나간다는 것에 큰 의미가 있다. 또한 메타록이 엔터테이먼트, 커머스
              기업 등과 협력관계를 맺은 데 이어 스포츠 분야까지 영역을 확장해
              나가면서 메타록의 닷큐브프로토콜의 확장성이 업종과 관계없이
              무한하다는 것이 확인되었다.
            </p>
            <p>
              메타록 서해영 대표는 “메타록은 10월 코엑스에서 개최된 메타버스
              전시회에 이어 11월 부산 벡스코에서 열리는 G-STAR 2022에도 참여할
              예정이라며 벌써 50여개의 업체의 미팅 제안이 오는 등 글로벌
              업체들에게도 관심이 뜨겁다. 앞으로도 대한민국의 기술이 글로벌로
              뻗어 나갈 수 있도록 많은 관심을 달라”고 전했다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDialogB;

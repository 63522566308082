import React from 'react';
import Layout from '../../components/en/Layout';

const ParentEn: React.FC = () => {
  return (
    <Layout>
      <div id="content2">
            <section id="patentMain" className="sub_main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">METAROCK · Patent/License</p>
                            <h3 className="wow fadeInDown" data-wow-delay="0.4s">METAROCK Patent and License Date </h3>
                        </div>
                    </div> 
                </div>
            </section>

            <section id="patent" className="sub_cont">
                <div className="container">
                    <div className="row">
                          <div className="col-12">
                              
                            <h4 className="wow fadeInDown" data-wow-delay="0.4s">Patent status</h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.6s">
                            <div className="thumb">
                                <img src="images/patent/patent1.png" alt="AI-based data processing system" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                            <div className="thumb">
                                <img src="images/patent/patent2.png"
                                    alt="Event-linked education service provision system" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.0s">
                            <div className="thumb">
                                <img src="images/patent/patent3.png"
                                    alt="Data center systems that provide personalized information" />
                            </div>
                        </div>
                        <div className="col-12 license">
                            <h4 className="wow fadeInDown" data-wow-delay="1.1s">License status </h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.2s">
                            <div className="thumb">
                                <img src="images/patent/patent4.png" alt="Research affiliated certificate" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.3s">
                            <div className="thumb">
                                <img src="images/patent/patent5.png" alt="patent and license" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.4s">
                            <div className="thumb">
                                <img src="images/patent/patent6.png"
                                    alt="Mail-order business registration certificate" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
  );
};

export default ParentEn;
import React ,{useEffect,useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { Autoplay, EffectFade } from "swiper";
import 'swiper/css/autoplay';
import "swiper/css/effect-fade";
import Header from '../../components/ko/Header';
import Footer from '../../components/ko/Footer';
import ContactModal from '../../components/ko/ContactModal';
import { Link } from "react-router-dom";
import $ from 'jquery'


const Game = () => {
  const [isOpenContact, setIsOpenContact] = useState(false);
  const onclickModal = () =>{
    setIsOpenContact(true)
  }
  useEffect(()=>{
    $("#game .fir .col-lg-4 li").click(function (this, e)  {
      e.preventDefault();
      $(this).addClass("on").siblings().removeClass("on");
  
      if ($("#game .fir .col-lg-4 li:eq(0)").hasClass("on")) {
        $(this)
          .parents(".col-lg-4")
          .siblings(".col-lg-8")
          .children("img")
          .attr("src", "images/test/game1.jpg");
        $(this)
          .parents(".col-lg-4")
          .siblings(".col-lg-8")
          .children("img")
          .attr("alt", "Esports Betting Game 서비스");
      } else if ($("#game .fir .col-lg-4 li:eq(1)").hasClass("on")) {
        $(this)
          .parents(".col-lg-4")
          .siblings(".col-lg-8")
          .children("img")
          .attr("src", "images/test/game2.jpg");
        $(this)
          .parents(".col-lg-4")
          .siblings(".col-lg-8")
          .children("img")
          .attr("alt", "Pro Esports 구단 전적 관리 시스템 구축");
      } else {
        $(this)
          .parents(".col-lg-4")
          .siblings(".col-lg-8")
          .children("img")
          .attr("src", "images/game/game3.png");
        $(this)
          .parents(".col-lg-4")
          .siblings(".col-lg-8")
          .children("img")
          .attr("alt", "전문 Esports News Journal 서비스");
      }
    });
  },[])
  return (
    <div className="wrap">
      <Header />
      <div id="content">
        <section id="gameMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">BUSINESS · 2WinChance</p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">2WinChance,<br />세계최초의 “Esports” 전략전술 대전
                  플랫폼</h3>
                <p className="text wow fadeInDown" data-wow-delay="0.6s">2WinChance는 세계 최초로 서비스되는 Esports 전략전술
                  대전게임으로 국내 뿐 아니라 다양한 국가에서 이미 주목받고 있습니다.</p>
              </div>
            </div>


            <Swiper
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              centeredSlides={true}
              modules={[Autoplay, EffectFade]}
              effect={"fade"}
              spaceBetween={50}
              slidesPerView={1}
              speed={2000}
            >
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
            </Swiper>


          </div>
        </section>

        <section id="game" className="sub_cont">
          <div className="container">
            <div className="row fir">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">이스포츠의 새로운 기회와 성장</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">2WinChance 플랫폼</h4>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <ul>
                  <li className="on wow fadeInLeft" data-wow-delay="0.6s">
                    <a title="contact">
                      <dl>
                        <dt>Esports <br className="pc" />전략/전술 대전</dt>
                        <dd>소속이 다른 정상급 프로 게이머를 고용, 레전드팀을 구성합니다. 전 세계의 플레이어들이 조합한 올스타팀들과 실시간으로 퍼포먼스를
                          겨뤄 승자를 가립니다.</dd>
                      </dl>
                    </a>
                  </li>
                  <li className="wow fadeInLeft" data-wow-delay="0.7s">
                    <a title="contact">
                      <dl>
                        <dt>세계 최초의 <br className="pc" />메타게임 장르화</dt>
                        <dd>Esports는 주류가 되는 플레이경향(메타)의 유행이 빠르게 변화하며, 경기에 막대한 영향을 미치게 됩니다. 선수선발 뿐 아니라
                          포메이션/운용에서의 두뇌싸움은 지금까지 경험하지 못한 즐거움을 제시할 것 입니다.</dd>
                      </dl>
                    </a>
                  </li>
                  <li className="wow fadeInLeft" data-wow-delay="0.8s">
                    <a title="contact">
                      <dl>
                        <dt>비지니스모델의 <br className="pc" />유연한 확장</dt>
                        <dd>인터내셔널(국가 별) 규제에 맞는 유연한 커스터마이징으로, 다양한 비지니스모델 적용이 가능합니다. 대한민국과 규제가 다른
                          유럽국가의 경우 국내 서비스로 별도의 수출버전 제작이 가능합니다.</dd>
                      </dl>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 wow fadeInRight" data-wow-delay="1.0s">
                <img src={`${process.env.PUBLIC_URL}/images/test/game1.jpg`} alt="Esports Betting Game 서비스" />
              </div>
            </div>
            <div className="row sec">
              <div className="col-lg-8 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.4s">
                <img src={`${process.env.PUBLIC_URL}/images/game/game4.png`} alt="모바일 디바이스 지원" />
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <dl>
                  <dt className="wow fadeInRight" data-wow-delay="0.6s">모바일 디바이스 지원</dt>
                  <dd className="wow fadeInRight" data-wow-delay="0.7s">별도의 어플리케이션 설치 없이 접속 가능</dd>
                  <dd className="wow fadeInRight" data-wow-delay="0.8s">웹 서버 트래픽 최소화 개발</dd>
                  <dd className="wow fadeInRight" data-wow-delay="0.9s">작은 화면에서도 컨테스트 조회 가능</dd>
                </dl>
              </div>
            </div>
          </div>
        </section>

        <section id="platform" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">2WinChance 플랫폼</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">성공적인 안착을 위한 목표</h4>
                <img src={`${process.env.PUBLIC_URL}/images/game/platform.png`} alt="성공적인 안착을 위한 목표" className="wow fadeInDown"
                  data-wow-delay="0.6s" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.8s">
                <div className="box">
                  <p>1단계</p>
                  <dl>
                    <dt>Esports 2WinChance Platform 개발</dt>
                    <dd>흥행이 검증된 북미형 스포츠판타지 게임을 Esports에 적합하도록 업그레이드 개발</dd>
                  </dl>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.9s">
                <div className="box">
                  <p>2단계</p>
                  <dl>
                    <dt>한국 출시 준비</dt>
                    <dd>게임물관리위원회 등급분류 진행</dd>
                    <dd>인기 Esports 프로게이머들의 실제 경기 데이터를 실시간 연동한 정보 제공</dd>
                  </dl>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="1.0s">
                <div className="box">
                  <p>3단계</p>
                  <dl>
                    <dt>글로벌 출시 준비</dt>
                    <dd>Esports에 대한 관심이 높은 SEA(동남아시아)선점</dd>
                    <dd>북미 등 추가 빅마켓 진출</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="gameContact" className="sub_contact">
          <div className="container">
            <div className="row">
              <h4 className="wow fadeInDown" data-wow-delay="0.4s">2WinChance와 함께하고 싶으신가요?</h4>
              {/* <button
    title="contact" 
    className="btn_contact wow fadeInUp"
    data-wow-delay="0.8s"
    onClick={() => {
      onclickModal()
    }}
  >
    CONTACT US
      </button> */}
              <Link to={"/contact"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
            </div>
          </div>
        </section>
      </div>
      {isOpenContact ? (
              <ContactModal setIsOpenContact={setIsOpenContact} />
            ) : null}
      <footer>
        <div className="container">
          <Footer />
        </div>
      </footer>
    </div>
  )
}

export default Game
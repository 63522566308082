import React , { useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'

const Footer = () => {
  useEffect(() => {
    $("footer .fnb li dd>a").click(function (e) {
      e.stopImmediatePropagation();

    })
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <ul className="fnb clear">
            <li>
              <dl>
                <dt className="wow fadeInLeft" data-wow-delay="0.4s">METAROCK</dt>
                <dd className="wow fadeInLeft" data-wow-delay="0.45s"><Link to={'/introduce'} title="회사소개" >회사소개</Link></dd>
                <dd className="wow fadeInLeft" data-wow-delay="0.5s"><Link to={'/history'} title="히스토리">History</Link></dd>
                <dd className="wow fadeInLeft" data-wow-delay="0.55s"><Link to={'/patent'} title="특허및인허가">특허 및 인허가</Link></dd>
                <dd className="wow fadeInLeft" data-wow-delay="0.6s"><Link to={'/partner'} title="파트너사">파트너사</Link></dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="wow fadeInLeft" data-wow-delay="0.6s">BUSINESS</dt>
                <dd className="wow fadeInLeft" data-wow-delay="0.7s"><Link to={'/metaverse'} title="닷큐브프로토콜">DotCube Protocol</Link></dd>
                <dd className="wow fadeInLeft" data-wow-delay="0.65s"><Link to={'/game'} title="투윈챈스">2WinChance</Link></dd>
                <dd className="wow fadeInLeft" data-wow-delay="0.75s"><Link to={'/heaven'} title="헤븐">Heaven</Link></dd>
                <dd className="wow fadeInLeft" data-wow-delay="0.8s"><Link to={'/mozza'} title="모짜와살리">Mozza &amp; Sali</Link></dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="wow fadeInLeft" data-wow-delay="0.85s">PR CENTER</dt>
                <dd className="wow fadeInLeft" data-wow-delay="0.9s"><Link to={'/notice'} title="공지사항">공지사항</Link></dd>
                <dd className="wow fadeInLeft" data-wow-delay="0.9s"><Link to={'/news'} title="메타록뉴스">메타록 뉴스</Link></dd>
                <dd className="wow fadeInLeft" data-wow-delay="0.95s"><Link to={'/sns'} title="소셜미디어">소셜미디어</Link></dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="wow fadeInLeft" data-wow-delay="1.0s">CAREERS</dt>
                <dd className="wow fadeInLeft" data-wow-delay="1.05s"><Link to={'/hire'} title="인재채용">인재채용</Link></dd>
                <dd className="wow fadeInLeft" data-wow-delay="1.15s"><Link to={'/faq'} title="채용faq">채용 FAQ</Link></dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="wow fadeInLeft" data-wow-delay="1.2s"><Link to={'/contact'} title="contact">CONTACT</Link></dt>
              </dl>
            </li>
          </ul>
        </div>
      </div>
      <p className="copy wow fadeInUp" data-wow-delay="1.25s">COPYRIGHT &copy; 2022. Metarock Inc. All Right Reserved.</p>
    </div>

  )
}

export default Footer
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/en/Layout';
import ContactModalEn from '../../components/en/ContactModalEn';

const HireEn: React.FC = () => {
    const [isOpenContactEn, setIsOpenContactEn] = useState(false);
    const onclickModalEn = () => {
        setIsOpenContactEn(true);
      };
  return (
    <Layout>
      <div id="content">
            <section id="hireMain" className="sub_main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">Careers · Recruitment</p>
                            <h3 className="wow fadeInDown" data-wow-delay="0.4s">We are recruiting experts<br />in each
                                field who will create a new world<br />connected with the metaverse.</h3>
                            <p className="wow fadeInDown" data-wow-delay="0.6s">Opportunities to work with METAROCK are
                                always open.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="hire">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.2s">
                            <h4>Development and growth of core technologies <br className="pc" />for the upcoming metaverse
                                era. <br className="pc" />Those are the value that METAROCK aims for.</h4>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <p className="wow fadeInRight" data-wow-delay="0.4s">Based on differentiated IT technology,
                                METAROCK is promoting various businesses such as metaverse and games. In the near-coming
                                great change, we aim to create technological innovations in each field to improve the
                                quality of life one step further.<br /><br />We are looking for talented people to
                                create a new world together with us, so please pay attention ton the following
                                recruitment fields.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="value" className="sub_cont">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">Our Core Value</p>
                            <h4 className="wow fadeInDown" data-wow-delay="0.4s">METAROCK's Core Talent Image</h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.6s">
                            <div className="thumb">
                                <img src="images/value/value1.png" alt="Challenging spirit" />
                            </div>
                            <dl>
                                <dt>Challenging spirit</dt>
                                <dd>Pursue constant innovation with a positive attitude.</dd>
                            </dl>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                            <div className="thumb">
                                <img src="images/value/value2.png" alt="Cooperation and Harmony" />
                            </div>
                            <dl>
                                <dt>Cooperation and Harmony</dt>
                                <dd>Always consider, respect and communicate with each other.</dd>
                            </dl>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.0s">
                            <div className="thumb">
                                <img src="images/value/value3.png" alt="Expertise" />
                            </div>
                            <dl>
                                <dt>Expertise</dt>
                                <dd>Have a goal and a sense of being the best in one’s field.</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </section>

            <section id="hireFaq">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-8 col-sm-12">
                            <h5 className="wow fadeInLeft" data-wow-delay="0.4s">Do you have any questions about
                                recruitment?</h5>
                            <p className="wow fadeInLeft" data-wow-delay="0.8s">Get answers to your questions through the
                                FAQ.</p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12 wow fadeInRight" data-wow-delay="1.2s">
                            <Link to="/faq_en" title="faq" className="btn_faq">Go to FAQ</Link>
                        </div>
                    </div>
                </div>
            </section>

            <section id="recruitment" className="sub_cont">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">Recruitment</p>
                            <h4 className="wow fadeInDown" data-wow-delay="0.4s">Information On Recruitment Fields</h4>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInLeft" data-wow-delay="0.2s">
                            <p>Game Development</p>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <ul>
                                <li className="wow fadeInRight" data-wow-delay="0.3s">Game planning/story setting, design
                                    and planning of system components</li>
                                <li className="wow fadeInRight" data-wow-delay="0.35s">Program/server programming, client
                                    programming</li>
                                <li className="wow fadeInRight" data-wow-delay="0.4s">Art/2D, 3D graphic design, original
                                    painting, modeling</li>
                                <li className="wow fadeInRight" data-wow-delay="0.45s">Directing/effects and sound effect
                                    UI/UX</li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInLeft" data-wow-delay="0.5s">
                            <p>VR business</p>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <ul>
                                <li className="wow fadeInRight" data-wow-delay="0.55s">VR business/planning, analysis,
                                    statistics, demand analysis, planning and execution of partnership promotion</li>
                                <li className="wow fadeInRight" data-wow-delay="0.6s">VR development/planning, programming,
                                    art</li>
                                <li className="wow fadeInRight" data-wow-delay="0.65s">Those who have experience in VR
                                    operation/VR Market</li>
                                <li className="wow fadeInRight" data-wow-delay="0.7s">Those who can actively participate in
                                    planning, design, communication, development, and operation</li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                            <p>Business</p>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <ul>
                                <li className="wow fadeInRight" data-wow-delay="0.85s">Business planning/game statistics,
                                    customer demand analysis, planning and execution of partnership promotion </li>
                                <li className="wow fadeInRight" data-wow-delay="0.9s">Publishing such as sourcing/sales and
                                    cost analysis</li>
                                <li className="wow fadeInRight" data-wow-delay="0.95s">Planning and execution of marketing
                                    and PR/on/offline advertising, promotion</li>
                                <li className="wow fadeInRight" data-wow-delay="1.0s">Understanding overseas
                                    business/overseas trends and data analysis</li>
                                <li className="wow fadeInRight" data-wow-delay="1.05s">Operation/game operation, metaverse
                                    operation and QA</li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInLeft" data-wow-delay="1.1s">
                            <p>Business support</p>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <ul>
                                <li className="wow fadeInRight" data-wow-delay="1.15s">Establishment and management of
                                    business planning/annual business plans, profit and loss and budget management</li>
                                <li className="wow fadeInRight" data-wow-delay="1.2s">Finance/accounting, budget management,
                                    financial accounting, tax accounting, finance-related review and analysis</li>
                                <li className="wow fadeInRight" data-wow-delay="1.25s">Planning and operation of overall
                                    work related to HR/analysis HR recruitment, evaluation, and compensation</li>
                                <li className="wow fadeInRight" data-wow-delay="1.3s">General affairs/efficient asset
                                    management, and an appropriate office environment</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section id="hireContact" className="sub_contact">
                <div className="container">
                    <div className="row">
                        <h4 className="wow fadeInDown" data-wow-delay="0.4s">Want to join METAROCK?</h4>
                        {/* <button
                    title="contact"
                    className="btn_contact wow fadeInUp"
                    data-wow-delay="0.8s"
                    onClick={() => {
                    onclickModalEn();
                    }}
                    >
                    CONTACT US
                  </button> */}
                  <Link to={"/contact_en"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
                    </div>
                </div>
            </section>
          </div>
          {isOpenContactEn ? (
              <ContactModalEn setIsOpenContactEn={setIsOpenContactEn} />
            ) : null}
    </Layout>
  );
};

export default HireEn;
import React, { useState } from "react";
import Footer from "../../components/ko/Footer";
import Header from "../../components/ko/Header";
import ContactModal from "../../components/ko/ContactModal";
import FaqItem from "./FaqItem";
import { Link } from "react-router-dom";

const Faq = () => {
  const [toggle, setToggle] = useState(Array.from({ length: 7 }, () => false));
  const [isOpenContact, setIsOpenContact] = useState(false);

  const fn_toggle = (num: number) => {
    const newArr = [...toggle];
    newArr[num] = !newArr[num];
    setToggle(newArr);
  };
  const onclickModal = () =>{
    setIsOpenContact(true)
  }

  return (
    <div id="content">
      <Header />
      <section id="faqMain" className="sub_main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="title wow fadeInDown" data-wow-delay="0.2s">
                Careers · FAQ
              </p>
              <h3 className="wow fadeInDown" data-wow-delay="0.3s">
                채용 관련
              </h3>
              <h3 className="wow fadeInDown" data-wow-delay="0.4s">
                자주 묻는 질문들 안내
              </h3>
            </div>
          </div>
        </div>
      </section>

      <section id="faq" className="sub_cont">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="title wow fadeInDown" data-wow-delay="0.2s">
                Frequently Asked Questions
              </p>
              <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                자주 묻는 질문들 안내
              </h4>
              <ul>
                <li className="wow fadeInDown" data-wow-delay="0.6s">
                  <dl>
                    <dt onClick={() => fn_toggle(0)}>
                      <a title="faq">
                        입사지원 방법은 어떻게 이루어지나요?
                        <i className={`fal ${toggle[0] ? "" : "fa-plus"}`}></i>
                        <i className="fal fa-minus"></i>
                      </a>
                    </dt>
                    <dd className={toggle[0] ? "show" : ""}>
                      당사의 공식 이메일 주소{" "}
                      <a href="mailto:recruit@metarock.co.kr">
                        (recruit@metarock.co.kr)
                      </a>
                      나 외부 구인구직 플랫폼에서 지원하실 수 있습니다.
                    </dd>
                  </dl>
                </li>
                <li className="wow fadeInDown" data-wow-delay="0.7s">
                  <dl>
                    <dt onClick={() => fn_toggle(1)}>
                      <a title="faq">
                        채용규모와 시기는 어떻게 되나요?
                        <i className={`fal ${toggle[1] ? "" : "fa-plus"}`}></i>
                        <i className="fal fa-minus"></i>
                      </a>
                    </dt>
                    <dd className={toggle[1] ? "show" : ""}>
                      일반적으로 연간 00명 내외의 신규 인력을 채용하며,
                      상시모집하고 있습니다.
                    </dd>
                  </dl>
                </li>
                <li className="wow fadeInDown" data-wow-delay="0.8s">
                  <dl>
                    <dt onClick={() => fn_toggle(2)}>
                      <a title="faq">
                        채용 프로세스는 어떻게 되나요?
                        <i className={`fal ${toggle[2] ? "" : "fa-plus"}`}></i>
                        <i className="fal fa-minus"></i>
                      </a>
                    </dt>
                    <dd className={toggle[2] ? "show" : ""}>
                      1차 서류 면접, 2차 담당 팀장 면접, 3차 임원진 면접 순으로
                      이루어집니다.
                    </dd>
                  </dl>
                </li>
                <li className="wow fadeInDown" data-wow-delay="0.9s">
                  <dl>
                    <dt onClick={() => fn_toggle(3)}>
                      <a title="faq">
                        지원자격에 연령제한이 있나요?
                        <i className={`fal ${toggle[3] ? "" : "fa-plus"}`}></i>
                        <i className="fal fa-minus"></i>
                      </a>
                    </dt>
                    <dd className={toggle[3] ? "show" : ""}>
                      만 20세 이상의 성인이 대상이며, 당사는 학위, 성별 및
                      인종에 차별을 두고 있지 않습니다.
                    </dd>
                  </dl>
                </li>
                <li className="wow fadeInDown" data-wow-delay="1.0s">
                  <dl>
                    <dt onClick={() => fn_toggle(4)}>
                      <a title="faq">
                        직원에 대한 복지는 어떤가요?
                        <i className={`fal ${toggle[4] ? "" : "fa-plus"}`}></i>
                        <i className="fal fa-minus"></i>
                      </a>
                    </dt>
                    <dd className={toggle[4] ? "show" : ""}>
                      법정 4대보험, 주5일 근무, 월차, 인센티브제, 스톡옵션, 경조
                      휴가 등의 복지제도를 운영하고 있습니다.
                    </dd>
                  </dl>
                </li>
                <li className="wow fadeInDown" data-wow-delay="1.1s">
                  <dl>
                    <dt onClick={() => fn_toggle(5)}>
                      <a title="faq">
                        졸업예정자도 지원이 가능한가요?
                        <i className={`fal ${toggle[5] ? "" : "fa-plus"}`}></i>
                        <i className="fal fa-minus"></i>
                      </a>
                    </dt>
                    <dd className={toggle[5] ? "show" : ""}>
                      졸업예정증명서를 우선적으로 제출해주시고 추후 학위증명서를
                      발급하여 전달해주시면 됩니다.
                    </dd>
                  </dl>
                </li>
                <li className="wow fadeInDown" data-wow-delay="1.2s">
                  <dl>
                    <dt onClick={() => fn_toggle(6)}>
                      <a title="faq">
                        서류는 어떤것을 준비해야 하나요?
                        <i className={`fal ${toggle[6] ? "" : "fa-plus"}`}></i>
                        <i className="fal fa-minus"></i>
                      </a>
                    </dt>
                    <dd className={toggle[6] ? "show" : ""}>
                      간단한 자기소개서와 이력서 그리고 해당자에 한해
                      졸업증명서, 학위증명서, 경력증명서 등이 필요합니다.
                    </dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="faqContact" className="sub_contact">
        <div className="container">
          <div className="row">
            <h4 className="wow fadeInDown" data-wow-delay="0.4s">
              METAROCK과 함께하고 싶으신가요?
            </h4>
            {/* <button
    title="contact" 
    className="btn_contact wow fadeInUp"
    data-wow-delay="0.8s"
    onClick={() => {
      onclickModal()
    }}
  >
    CONTACT US
      </button> */}
            <Link to={"/contact"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
          </div>
        </div>
      </section>
      {isOpenContact ? (
              <ContactModal setIsOpenContact={setIsOpenContact} />
            ) : null}
      <footer>
        <div className="container">
          <Footer />
        </div>
      </footer>
    </div>
  );
};

export default Faq;

import { Dispatch, SetStateAction } from "react";

const ContactModalEn = ({
  setIsOpenContactEn,
}: {
  setIsOpenContactEn: Dispatch<SetStateAction<boolean>>;
}) => {
  const closeContactEn = () => {
    setIsOpenContactEn(false);
  };

  return (
    <div id="popup_layer" className="contact_popup">
        <div>
        <div className="layer_popup_header">
        <h1>Contact Us</h1>
        </div>      
      <div id="Contactlayer_popup" className="row">
     
        <div className="layer_scroll">
          <form>
            <label>
              <span className="title">Company<sup>*</sup></span>
              <input type="text" />
            </label>
            <label>
              <span className="title">Department</span>
              <input type="text" />
            </label>
            <label>
              <span className="title">Name<sup>*</sup></span>
              <input type="text" />
            </label>
            <label>
              <span className="title">Position</span>
              <input type="text" />
            </label>
            <label>
              <span className="title">Phone number</span>
              <input type="text" />
            </label>
            <label>
              <span className="title">Email<sup>*</sup></span>
              <input type="text" />
            </label>
            <label className="Category">
              <span className="title">Category<sup>*</sup></span>
                <select className="Category_select">
                  <option>Metaverse construction</option>
                  <option>Metaverse alliance</option>
                  <option>game publishing</option>
                  <option>others</option>
              </select>
            </label>
            <label>
              <span className="title">Title<sup>*</sup></span>
              <input type="text" />
            </label>
            <label className="ContText">
                <span className="title">Text<sup>*</sup></span>
                <textarea placeholder="Please enter your details." id="contentText" name="contentText" required>
                  
                </textarea>
            </label>
            <label htmlFor="file">
                <span className="title">Attachments</span>
                <p className="file_btn_wrap"><span className="file_btn">File Select</span><span className="file_btn_alert">No file selected.</span></p>
              <input
                type="file"
                id="file"
                name="file"
                className="file"
                onChange={(e) => console.log(e.target)}
                multiple />
              </label>
              <label className="Contactalert">
              *By clicking the send button, you agree to the collection/use of personal information.
              </label>
        </form>
          

        </div>
        <div className="Contactmedal_btn">
        <button className="send_btn">Send</button>
        <button className="close_btn" onClick={closeContactEn}>Close</button>
        </div>
    </div >
  </div >

    </div >
  )
}

export default ContactModalEn;     
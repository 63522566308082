import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade} from "swiper";
import 'swiper/css/autoplay';
import "swiper/css/effect-fade";
import Footer from '../../components/ko/Footer'
import Header from '../../components/ko/Header'
import ContactModal from '../../components/ko/ContactModal'
import { Link } from "react-router-dom";

const Mozza = () => {
  const [isOpenContact, setIsOpenContact] = useState(false);
  
  const onclickModal = () =>{
    setIsOpenContact(true)
  }
  return (
    <div className="wrap">
      <Header />
      <div id="content">
        <section id="mozzaMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">BUSINESS · Mozza &amp; Sali</p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">Mozza &amp; Sali<br />Full CG 3D Animation
                </h3>
              </div>
            </div>
            <Swiper
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              centeredSlides={true}
              modules={[Autoplay, EffectFade]}
              effect={"fade"}
              spaceBetween={50}
              slidesPerView={1}
              speed={3000}
            >
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
              <SwiperSlide></SwiperSlide>
            </Swiper>
          </div>
        </section>

        <section id="storyline" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">Storyline</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">모짜와 살리의 <br className="m" />수상한 버디 애니메이션</h4>
                <p className="text wow fadeInDown" data-wow-delay="0.6s">슈퍼히어로의 조수들이자 라이벌 ‘모짜와 살리’ 그들의 대결은 스승
                  ‘슈울짱(슈퍼울트라 짱짱맨)’이 준 미션뿐만 아니라, <br className="pc" />청소하고 음식 만드는 등의 하찮은 일에까지 이어진다.</p>
                <img src={`${process.env.PUBLIC_URL}/images/mozza/storyline.png`} alt="Character Design" className="wow fadeInUp"
                  data-wow-delay="0.8s" />
                <p className="text wow fadeInUp" data-wow-delay="1.0s">Character Design</p>
              </div>
            </div>
          </div>
        </section>

        <section id="mozza">
          <div className="container">
            <div className="row fir">
              <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.2s">
                <img src={`${process.env.PUBLIC_URL}/images/mozza/mozza1.png`} alt="명료한 비비드 톤(Vivid Tone)" />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInRight" data-wow-delay="0.4s">
                <p>Color Concept</p>
                <dl>
                  <dt>명료한 비비드 톤(Vivid Tone)</dt>
                  <dd>뒤죽박죽 된 배경의 재미, 통통 튀는 캐릭터들의 성격을 잘 살리기 위해 컬러는 역동적이고(Dynamic), 강렬하고(Intensity),
                    풍부한(Abundant) 느낌의 비비드 톤(Vivid Tone)으로 구성하였습니다.</dd>
                </dl>
              </div>
            </div>
            <div className="row sec">
              <div className="col-lg-6 col-md-12 col-sm-12 right wow fadeInRight" data-wow-delay="0.6s">
                <img src={`${process.env.PUBLIC_URL}/images/mozza/mozza2.png`} alt="선명하고 산뜻한 느낌의 발랄한 영상" />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                <p>Tone &amp; Manner</p>
                <dl>
                  <dt>선명하고 산뜻한 느낌의 발랄한 영상</dt>
                  <dd>단순화되고 선명하고 산뜻한 컬러와 Low Key와 Medium Key를 적절히 조화한 Tone. 모션에 어울리는 경쾌하고 발랄한 BGM, Sound,
                    SFX</dd>
                </dl>
              </div>
            </div>
          </div>
        </section>

        <section id="plan" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">Business plan</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">아이부터 어른까지 <br className="m" />어필할 수 있는 콘텐츠 제작
                </h4>
                <p className="text wow fadeInDown" data-wow-delay="0.6s">Adulkid부터 Kidult까지 함께 공감하고 즐길 수 있는 슬랩스틱
                  코미디로, 사업의 확장과 수익을 극대화하기 위해 <br className="pc" />멀티플랫폼 환경의 트랜스미디어 콘텐츠 제작됩니다.</p>
                <img src={`${process.env.PUBLIC_URL}/images/mozza/plan.png`} alt="아이부터 어른까지 어필할 수 있는 콘텐츠 제작" className="wow fadeInUp"
                  data-wow-delay="0.8s" />
                <p className="text wow fadeInUp" data-wow-delay="1.0s">Mozza &amp; Sali 애니메이션은 OSMU,
                  Collaboration, Co Work를 기반으로 다양한 분야의 상품 판매를 통해 <br className="pc" />수익을 이끌어낼 수 있는 즉, 트랜스미디어에
                  용이한 형식의 고부가가치 콘텐츠입니다.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="mozzaContact" className="sub_contact">
          <div className="container">
            <div className="row">
              <h4 className="wow fadeInDown" data-wow-delay="0.4s">METAROCK과 함께하고 싶으신가요?</h4>
              {/* <button
    title="contact" 
    className="btn_contact wow fadeInUp"
    data-wow-delay="0.8s"
    onClick={() => {
      onclickModal()
    }}
  >
    CONTACT US
      </button> */}
              <Link to={"/contact"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
            </div>
          </div>
        </section>
      </div>
      {isOpenContact ? (
              <ContactModal setIsOpenContact={setIsOpenContact} />
            ) : null}
      <footer>
        <div className="container">
          <Footer />
        </div>
      </footer>
    </div >
  )
}

export default Mozza
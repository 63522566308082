import React, { useState } from "react";
import Layout from "../../components/en/Layout";
import ContactModalEn from "../../components/en/ContactModalEn";
import { Link } from "react-router-dom";

const FaqEn: React.FC = () => {
  const [isOpenContactEn, setIsOpenContactEn] = useState(false);
  const onclickModalEn = () => {
      setIsOpenContactEn(true);
    };
  const [toggle, setToggle] = useState(Array.from({ length: 7 }, () => false));

  const fn_toggle = (num: number) => {
    const newArr = [...toggle];
    newArr[num] = !newArr[num];
    setToggle(newArr);
  };

  return (
    <Layout>
      <div id="content">
        <section id="faqMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">
                  Careers · FAQ
                </p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">
                  Recruitment related FAQ
                </h3>
              </div>
            </div>
          </div>
        </section>

        <section id="faq" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">
                  Frequently Asked Questions
                </p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                  Frequently asked questions
                </h4>
                <ul>
                  <li className="wow fadeInDown" data-wow-delay="0.6s">
                    <dl>
                      <dt onClick={() => fn_toggle(0)}>
                        <a title="faq">
                          How should I apply for a job?
                          <i
                            className={`fal ${toggle[0] ? "" : "fa-plus"}`}
                          ></i>
                          <i className="fal fa-minus"></i>
                        </a>
                      </dt>
                      <dd className={toggle[0] ? "show" : ""}>
                        You can apply through our official email address{" "}
                        <a href="mailto:recruit@metarock.co.kr">
                          (recruit@metarock.co.kr)
                        </a>{" "}
                        or an external job search platform.
                      </dd>
                    </dl>
                  </li>
                  <li className="wow fadeInDown" data-wow-delay="0.7s">
                    <dl>
                      <dt onClick={() => fn_toggle(1)}>
                        <a title="faq">
                          What is the size and timing of recruitment?
                          <i
                            className={`fal ${toggle[1] ? "" : "fa-plus"}`}
                          ></i>
                          <i className="fal fa-minus"></i>
                        </a>
                      </dt>
                      <dd className={toggle[1] ? "show" : ""}>
                        Generally, we hire about 00 new manpower every year on a
                        permanent basis.
                      </dd>
                    </dl>
                  </li>
                  <li className="wow fadeInDown" data-wow-delay="0.8s">
                    <dl>
                      <dt onClick={() => fn_toggle(2)}>
                        <a title="faq">
                          What is the hiring process like?
                          <i
                            className={`fal ${toggle[2] ? "" : "fa-plus"}`}
                          ></i>
                          <i className="fal fa-minus"></i>
                        </a>
                      </dt>
                      <dd className={toggle[2] ? "show" : ""}>
                        The 1st documentary interview, the 2nd interview with
                        the team leader in charge, and the 3rd interview with
                        the executives in order.
                      </dd>
                    </dl>
                  </li>
                  <li className="wow fadeInDown" data-wow-delay="0.9s">
                    <dl>
                      <dt onClick={() => fn_toggle(3)}>
                        <a title="faq">
                          Is there any age limit on the qualifications to apply?
                          <i
                            className={`fal ${toggle[3] ? "" : "fa-plus"}`}
                          ></i>
                          <i className="fal fa-minus"></i>
                        </a>
                      </dt>
                      <dd className={toggle[3] ? "show" : ""}>
                        Adults over the age of 20 can apply, and we do not
                        discriminate based on degree, gender or race.
                      </dd>
                    </dl>
                  </li>
                  <li className="wow fadeInDown" data-wow-delay="1.0s">
                    <dl>
                      <dt onClick={() => fn_toggle(4)}>
                        <a title="faq">
                          How about the welfare of employees?
                          <i
                            className={`fal ${toggle[4] ? "" : "fa-plus"}`}
                          ></i>
                          <i className="fal fa-minus"></i>
                        </a>
                      </dt>
                      <dd className={toggle[4] ? "show" : ""}>
                        We operate welfare systems such as four major legal
                        insurances, five-day work week, monthly leave, incentive
                        system, stock option, and leave for congratulations and
                        condolences.
                      </dd>
                    </dl>
                  </li>
                  <li className="wow fadeInDown" data-wow-delay="1.1s">
                    <dl>
                      <dt onClick={() => fn_toggle(5)}>
                        <a title="faq">
                          Can prospective graduates apply?
                          <i
                            className={`fal ${toggle[5] ? "" : "fa-plus"}`}
                          ></i>
                          <i className="fal fa-minus"></i>
                        </a>
                      </dt>
                      <dd className={toggle[5] ? "show" : ""}>
                        Submit the certification of the expectant graduation
                        first, then issue and deliver the degree certificate
                        later.
                      </dd>
                    </dl>
                  </li>
                  <li className="wow fadeInDown" data-wow-delay="1.2s">
                    <dl>
                      <dt onClick={() => fn_toggle(6)}>
                        <a title="faq">
                          What documents do I need to prepare?
                          <i
                            className={`fal ${toggle[6] ? "" : "fa-plus"}`}
                          ></i>
                          <i className="fal fa-minus"></i>
                        </a>
                      </dt>
                      <dd className={toggle[6] ? "show" : ""}>
                        A brief self-introduction letter, resume, and only the
                        relevant applicants are required to submit a graduation
                        certificate, degree certificate, or career certificate.
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="faqContact" className="sub_contact">
          <div className="container">
            <div className="row">
              <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                Want to join METAROCK?
              </h4>
              {/* <button
                    title="contact"
                    className="btn_contact wow fadeInUp"
                    data-wow-delay="0.8s"
                    onClick={() => {
                    onclickModalEn();
                    }}
                    >
                    CONTACT US
                  </button> */}
                      <Link
                to="/contact_en"
                title="contact"
                className="btn_contact wow fadeInUp"
                data-wow-delay="0\.8s"
              >
                CONTACT US
              </Link>
            </div>
          </div>
        </section>
      </div>
      {isOpenContactEn ? (
              <ContactModalEn setIsOpenContactEn={setIsOpenContactEn} />
            ) : null}
    </Layout>
  );
};

export default FaqEn;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "../../../components/en/Layout";
import {
  get_articleDetail,
  uset_boardReadCntIncrease,
} from "../../../utils/axios";

interface articleDataInterface {
  regId: string;
  regDt: string;
  articleContent: string;
  articleSubject: string;
}

const NewsEn: React.FC = () => {
  const [articleDetail, setArticleDetail] =
    useState<articleDataInterface | null>(null);
  const [articleFilesList, setArticleFilesList] = useState([]);
  const location = useLocation();

  const get_articleDatail = async (id: string) => {
    const params = {
      boardIdx: "2",
      articleIdx: id,
    };
    try {
      const { data } = await get_articleDetail(params);
      setArticleDetail(data.articleDetail);
      setArticleFilesList(data.articleFilesList);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const articleId = location.search.split("t=")[1];
    get_articleDatail(articleId);
    uset_boardReadCntIncrease(articleId);
  }, []);

  return (
    <Layout>
      <div id="content">
        <section id="noticeMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">
                  PR Center · Notice
                </p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">
                  METAROCK Notice
                </h3>
              </div>
            </div>
          </div>
        </section>

        <section id="noticeDetailCont">
          <div className="container">
            <h2 className="noticeTitle wow fadeIn">공지사항</h2>
            <div className="noticeDetailHeader wow fadeInLeft">
              <p className="title">{articleDetail?.articleSubject}</p>
              <ul className="noticeListInfo">
                <li>{articleDetail?.regId}</li>
                <li>{articleDetail?.regDt}</li>
              </ul>
            </div>
            {articleDetail?.articleContent ? (
              <div
                className="noticeView wow fadeInLeft sun-editor-editable"
                dangerouslySetInnerHTML={{
                  __html: articleDetail.articleContent,
                }}
              ></div>
            ) : null}
            {articleFilesList.map(({ file_name, file_url }, i) => {
              return (
                <a
                  href={file_url}
                  key={i}
                  className="noticeFile wow fadeInLeft"
                  rel="noopener"
                >
                  {file_name}
                </a>
              );
            })}
            <Link to="/notice_en" className="noticeBtn wow fadeInRight">
              List
            </Link>
          </div>
        </section>
        <section id="noticeContact" className="sub_contact">
          <div className="container">
            <div className="row">
              <h4 className="wow fadeInDown" data-wow-delay="0.4s">
                Do you have any questions about METAROCK?
              </h4>
              <Link
                to="/contact_en"
                title="contact"
                className="btn_contact wow fadeInUp"
                data-wow-delay="0\.8s"
              >
                CONTACT US
              </Link>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default NewsEn;

import React from 'react';
import Layout from '../../components/en/Layout';

const HistoryEn: React.FC = () => {
  return (
    <Layout>
      <div id="content2">
            <section id="historyMain" className="sub_main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">METAROCK · History</p>
                            <h3 className="wow fadeInDown" data-wow-delay="0.4s">METAROCK History</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section id="history" className="sub_cont">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">History</p>
                            <h4 className="wow fadeInDown" data-wow-delay="0.4s">METAROCK History</h4>
                            <ul>
                                <li className="wow fadeInDown" data-wow-delay="0.5s">
                                <span className="date">2023.06</span>
                                    <p>Signed  MoU with Nuri Flex Co., Ltd to build a ‘Sports Metaverse Zone’</p>
                                    <p>Signed MoU to build a US hip-hop label Empire Distribution’s ‘Hip-hop Metaverse with ’Liaison Advisory Group</p>
                                </li>
                                <li className="wow fadeInDown" data-wow-delay="0.6s">
                                <span className="date">2023.05</span>
                                    <p>Signed MoU with Atom Holdings for ‘Joint Business on Atom Metaverse Development and Services’</p>
                                </li>
                                <li className="wow fadeInDown" data-wow-delay="0.7s">
                                    <span className="date">2023.03</span>
                                    <p>Fantasy Sports Game WINNERPICK launched</p>
                                    <p>Signed a contract or Advertising solution in Metaverse with EDAI</p>
                                    <p>Korea self Regulatory Fair Trade Federation, Select DCP as the official Metaverse</p>
                                    <p>The world’s 1st decentralized metaverse protocol DCP(DotCube Protocol) soft launched</p>
                                  
                                </li>
                                <li className="wow fadeInDown" data-wow-delay="0.8s">
                                    <span className="date">2023.02</span>
                                    <p>Signed Advertising agency contract · media partnership with Mezzomedia Inc.</p>
                                    <p>Signed Agreement for cooperation on Global Business of DCP with Tencent Cloud</p>
                                    <p>Sports Prediction Game IMPICK service launched</p>
                                </li>
                                <li className="wow fadeInDown" data-wow-delay="0.9s">
                                    <span className="date">2023.01</span>
                                    <p>Signed “Business Agreement for Metaverse Business” with EDAI</p>
                                    <p>Signed “Plastic surgery information platform based on Metaverse Joint Development Agreement” with gloHEAD Co. Ltd</p>
                                </li>
                                <li className="wow fadeInDown" data-wow-delay="1.0s">
                                <span className="date">2022.12</span>
                                    <p>Signed “Business Agreement for Metaverse Business” with Uptempo global Co., Ltd.</p>
                                    <p>Signed “Business Agreement for Metaverse Business” with Morisawa korea Inc.</p>
                                    <p>Signed “Business Agreement for Metaverse Business” with WannaVerse Inc.</p>
                                    <p>Signed “Business Agreement for Metaverse Business” with TUNiB</p>
                                  </li>
                                <li className="wow fadeInDown" data-wow-delay="1.2s">
                                <span className="date">2022.11</span>
                                    <p>Signed "CRATOS METAVERSE Development Agreement" with Paros Labs Inc.</p>
                                    <p>Signed "Investment agreement(30 billion won)" with Blitz Investment Development Inc.</p>
                                    <p>Signed "Steel Duck Project Animation, Game, and Metaverse Development Business Agreement" with 7Billion Labs Inc.</p>
                                  </li>
                                <li className="wow fadeInDown" data-wow-delay="1.3s">
                                <span className="date">2022.10</span>
                                    <p>Signed business agreement for cooperation on metaverse based platform business with Tomatochain Inc.</p>
                                    <p>Signed a business agreement on joint development "Spain football league La Liga Metaverse" with TVM Inc.</p>
                                    <p>Signed a business agreement for "build a K-pop audition metaverse" with TuneGem Inc.</p>
                                  </li>
                                <li className="wow fadeInDown" data-wow-delay="1.4s">
                                <span className="date">2022.09</span>
                                    <p>Signed "Agreement of develop NFT Marketplace and Wallet based on metaverse" with MABC PTE Ltd</p>
                                  </li>
                                <li className="wow fadeInDown" data-wow-delay="1.5s">
                                <span className="date">2022.08</span>
                                    <p>Signed MOArelated to "Construction of Metaverse for Traffic Safety Education" with Korea Automobile Association</p>
                                  </li>
                                <li className="wow fadeInDown" data-wow-delay="1.6s">
                                <span className="date">2022.07</span>
                                    <p>Signed "AIMY Moon Metahuman & Metaverse Construction Agreement" with AIM Music
                                    </p>
                                  </li>
                                <li className="wow fadeInDown" data-wow-delay="1.7s">
                                <span className="date">2022.06</span>
                                    <p>Signed "Business Agreement on Investment in 2Winchance & P2E Games"with Leaders technology investment co Ltd</p>
                                    <p>Signed "MABC Art Metaverse Construction contract & Phase 1 Construction Project started" with MABC PTE Ltd</p>
                                  </li>
                                <li className="wow fadeInDown" data-wow-delay="1.8s">
                                <span className="date">2022.04</span>
                                    <p>Signed "AMIY Moon Metaverse Joint Development Agreement" with AIM Music</p>
                                    <p>Signed "Agreement on cooperation in the supply of games such as board games" with One-A Net</p>
                                    <p>Signed "Agreement on cooperation in the implementation of pet metaverse" with MONGMONG-E Inc.</p>
                                  </li>
                                  
                                <li className="wow fadeInDown" data-wow-delay="1.9s">
                                <span className="date date_2022">2022.03</span>
                                    <p>Changed company name as METAROCK Inc.</p>
                                    <p>CEO Seo haeyoung inaugurated</p>
                                    <p>Add Business Purpose <br className="pc" />
                                    (Game services, Soft ware development and supply etc...)</p>
                                </li>
                                <li className="wow fadeInDown" data-wow-delay="2.1s">
                                <span className="date">2019.03</span>
                                    <p>Founded ZTech-I Inc.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            </div>
    </Layout>
  );
};
   
export default HistoryEn;
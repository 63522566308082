import React from 'react';
import Main from '../../components/ko/Main';
//import Modal from '../../components/ko/Modal';   //모달 주주총회 - 메인화면 팝업 공지
//import ModalBottom from '../../components/ko/ModalBottom'; //모달 허위신고




const Homeko = () => {
  return (<div>
    {/* <ModalBottom />  */}
    {/* <Modal />  */}   
    <Main />
  </div>);
};

export default Homeko;

import React,{useState} from 'react';
import Footer from './Footer';
import Header from './Header';


const Layout = ({ children, contect }: { children: React.ReactNode, contect?: boolean }) => {

    return (
        <div>
            <Header />
            <div id='en'>
                {children}
                
            </div>
            <Footer contect={contect} />
            
        </div>
        
    );
};

export default Layout;
import React from 'react';
import Layout from '../../components/en/Layout';

const PartnerEn: React.FC = () => {
  return (
    <Layout>
      <div id="content2">
            <section id="partnersMain" className="sub_main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="title wow fadeInDown" data-wow-delay="0.2s">METAROCK · Partners</p>
                            <h3 className="wow fadeInDown" data-wow-delay="0.4s">METAROCK Partnership</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section id="patners" className="sub_cont">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                          <p className="title wow fadeInDown" data-wow-delay="0.2s">Partners</p>
                          <h4 className="wow fadeInDown" data-wow-delay="0.4s">METAROCK Partnership</h4>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.6s">
                            <div className="thumb">
                               <div className="card">
                                    <div className="front">
                                    </div>
                                  <div className="back">
                                  <button onClick={() => window.open('https://tvm.inc/', '_blank')}>TVM/LALIGA</button>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.65s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                 
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://www.mabc.io/', '_blank')}>MABC</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.7s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                          
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://www.enterarts.net/a-i-m', '_blank')}>A.I.M</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.75s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('', '_blank')}>Pharos Labs</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://www.tunegem.io/', '_blank')}>K-Pop Factory</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        {/*  
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.85s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('http://picoentech.com/', '_blank')}>Picoentech</button>
                                  </div>
                             </div>
                            </div>
                          </div>
                        */}
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.9s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('http://www.leadersti.co.kr/index7.php', '_blank')}>Leaders Technology Investment</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.0s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                    
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://smartstore.naver.com/flexxme?', '_blank')}>Flexme</button>
                                  </div>
                             </div>
                            </div>
                        </div>
       
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.2s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                    
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://tomatochain.net/', '_blank')}>Tomato Chain</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.25s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://uptempo-global.com/', '_blank')}>Uptempo-Global</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.3s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                 
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://www.morisawa.co.kr/', '_blank')}>Morisawa Korea</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.35s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                   
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://wannaverse.com/', '_blank')}>WannaVerse</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.4s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                   
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://www.tunib.ai/', '_blank')}>Tunib</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.45s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                    
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://www.okta.net/okta/', '_blank')}>World Feddration of Overseas Korean Traders Associations</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.5s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://afab.gov.ph/', '_blank')}>Authority of the Freeport Area of Bataan</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.55s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                    
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('', '_blank')}>7 billion Labs</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.6s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                  
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('http://www.mongepark.com/default/index.php', '_blank')}>MONGMONG-E</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.65s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                    
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://www.sdxfoundation.org/', '_blank')}>SDX Foundation</button>
                                  </div>
                             </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.65s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://www.alibabacloud.com/ko', '_blank')}>Alibaba cloud</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.7s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://edai.imweb.me/', '_blank')}>EDAI</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.75s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('', '_self')}>gloHEAD</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.8s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://www.mezzomedia.co.kr/', '_blank')}>Mezzomedia</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.85s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://www.tencentcloud.com/ko', '_blank')}>Tencent cloud</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.85s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('', '_blank')}>Atom Holdings</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.85s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('http://www.nuritelecom.co.kr/kr/main/main.html', '_blank')}>Nuri Flex Co.,Ltd</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.85s">
                            <div className="thumb">
                            <div className="card">
                                      <div className="front">
                                     
                                  </div>
                                      <div className="back">
                                      <button onClick={() => window.open('https://www.liaisonadvisory.com/', '_blank')}>Liaison Advisory Group</button>
                                  </div>
                             </div>
                            </div>
                        </div>
                      </div>
                </div>
            </section>
        </div>
    </Layout>
  );
};

export default PartnerEn;
import React , { useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'

const Header = () => {
  useEffect(() => {
    $("header .gnb_pc").hover(function (e) {
      e.preventDefault();
      $("header h1 img").attr("src", "images/logo/logo.png");
      $("header").css({
        "background-color": "rgba(255, 255, 255, 1)",
        "box-shadow": "0 8px 32px 0 rgba(31, 38, 135, 0.1)",
        "border-bottom": "1px solid rgba(255, 255, 255, 0.18)"
      });
      $("header .gnb_pc a").css("color", "#000");
      $(this).find(".dropdown").stop().slideDown(200);
    });
    
    $("header .gnb_pc").mouseleave(function (e) {
      e.preventDefault();
      $("header h1 img").attr("src", "images/logo/logo-white.png");
      $("header").css({
        "background-color": "rgba(0, 0, 0, 0.6)",
        "box-shadow": "0 8px 32px 0 rgba(31, 38, 135, 0.1)",
        "border-bottom": "1px solid rgba(255, 255, 255, 0.18)"
      });
      $("header .gnb_pc .menu > li > a").css("color", "#fff");
      $("header .gnb_pc .lang > li > a").css("color", "#fff");
      $(this).find(".dropdown").stop().slideUp(500);
    });
  }, []);
  return (
    <header>
      <h1>
        <Link to="/home_en" title="home_en">
          <img src="/images/logo/logo-white.png" alt="METAROCK_logo" />
        </Link>
      </h1>
      
      <nav>
        <h2 className="hidden">MainMenu</h2>
        <div className="gnb_pc clear">
          <ul className="menu clear">
            <li>
              <a href="javascript:void(0);" title="metarock">
                METAROCK
              </a>
          
            </li>
            <li>
              <a href="javascript:void(0);" title="business">
                BUSINESS
              </a>
       
            </li>
            <li>
              <a href="javascript:void(0);" title="prcenter">
                PR CENTER
              </a>

            </li>
            <li>
              <a href="javascript:void(0);" title="careers">
                CAREERS
              </a>

            </li>
            <li>
              <Link to="/contact_en" title="contact">
                CONTACT
              </Link>
            </li>
          </ul>
          <div className="dropdown">
            <ul>
              <li>
              <ul>
                <li>
                  <Link to="/intro_en" title="about">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/history_en" title="history">
                    History
                  </Link>
                </li>
                <li>
                  <Link to="/patent_en" title="patent and License">
                    Patent and License
                  </Link>
                </li>
                <li>
                  <Link to="/partner_en" title="partner">
                    Partners
                  </Link>
                </li>
              </ul>
              </li>
              <li>
              <ul>
                <li>
                  <Link to="/metaverse_en" title="dotcubeprotocol">
                    DotCube Protocol
                  </Link>
                </li>
                <li>
                  <Link to="/game_en" title="2winchance">
                    2WinChance
                  </Link>
                </li>
                <li>
                  <Link to="/heaven_en" title="heaven">
                    Heaven
                  </Link>
                </li>
                <li>
                  <Link to="/mozza_en" title="mozza_and_sail">
                    Mozza &amp;Sali
                  </Link>
                </li>
              </ul>
              </li>
              <li>
              <ul>
                <li>
                  <Link to="/notice_en" title="notice">
                    METAROCK Notice
                  </Link>
                </li>
                <li>
                  <Link to="/news_en" title="news">
                    METAROCK News
                  </Link>
                </li>
                <li>
                  <Link to="/sns_en" title="sns">
                    Social Media
                  </Link>
                </li>
              </ul>
              </li>
              <li>
              <ul>
                <li>
                  <Link to="/hire_en" title="hecruitment">
                    Recruitment
                  </Link>
                </li>
                <li>
                  <Link to="/faq_en" title="faq">
                    Recruitment FAQ
                  </Link>
                </li>
              </ul>
              </li>
              <li>
                <ul>
                  <li>
                <Link to="/contact_en" title="contact" style={{ width:"216px" }}>
                  CONTACT
                </Link>
                  </li>
                </ul>
              </li>
            </ul>

          </div>
          <ul className="lang">
            <li>
            <a style={{ cursor: 'pointer' }} title='lang'><span className="material-icons">language</span></a>
              <ul>
                <li>
                  <Link to="/" title="kor">
                    KOR
                  </Link>
                </li>
                <li className="on">
                  <a href="" title="eng">
                    ENG
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="side_menu">
          <div className="burger_box">
            <div className="menu-icon-container">
              <a
                href=""
                title="menuicon"
                className="menu-icon js-menu_toggle closed"
              >
                <span className="menu-icon_box">
                  <span className="menu-icon_line menu-icon_line--1"></span>
                  <span className="menu-icon_line menu-icon_line--2"></span>
                  <span className="menu-icon_line menu-icon_line--3"></span>
                </span>
              </a>
            </div>
          </div>
          <Link to="/home_en">
          <img src="/images/logo/logo.png" alt="METAROCK_LOGO" className='side_menu_logo'/>
          </Link>
          <ul className="lang clear">
            <li>
              <Link to="/" title="contact">
                {/* <img src="/images/nation/lang_ko.png" alt="KOREA" /> */}
                <p>KOR</p>
              </Link>
            </li>
            <li className="on">
              <a href="" title="eng">
                {/* <img src="/images/nation/lang_en.png" alt="ENGLISH" /> */}
                <p>ENG</p>
              </a>
            </li>
          </ul>
          <div className="gnb_m">
            <ul className="clear">
              <li className="has_dropdown">
                <a href="" title="metarock">
                  METAROCK<i className="far fa-chevron-down"></i>
                </a>
                <ul className="dropdown">
                  <li>
                    <Link to="/intro_en" title="about">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/history_en" title="history">
                      History
                    </Link>
                  </li>
                  <li>
                    <Link to="/patent_en" title="patent and License">
                      Patent and License
                    </Link>
                  </li>
                  <li>
                    <Link to="/partner_en" title="partner">
                      Partners
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="has_dropdown">
                <a href="" title="business">
                  BUSINESS<i className="far fa-chevron-down"></i>
                </a>
                <ul className="dropdown">
                  <li>
                    <Link to="/metaverse_en" title="DotCubeProtocol">
                      DotCube Protocol
                    </Link>
                  </li>
                  <li>
                    <Link to="/game_en" title="2WinChance">
                      2WinChance
                    </Link>
                  </li>
                  <li>
                    <Link to="/heaven_en" title="heaven">
                      Heaven
                    </Link>
                  </li>
                  <li>
                    <Link to="/mozza_en" title="mozza&sali">
                      Mozza &amp; Sali
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="has_dropdown">
                <a href="" title="pr_center">
                  PR CENTER<i className="far fa-chevron-down"></i>
                </a>
                <ul className="dropdown">
                  <li>
                    <Link to="/notice_en" title="notice">
                      METAROCK Notice
                    </Link>
                  </li>
                  <li>
                    <Link to="/news_en" title="news">
                      METAROCK News
                    </Link>
                  </li>
                  <li>
                    <Link to="/sns_en" title="sns">
                      Social Media
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="has_dropdown">
                <a href="" title="careers">
                  CAREERS<i className="far fa-chevron-down"></i>
                </a>
                <ul className="dropdown">
                  <li>
                    <Link to="/hire_en" title="recruitment">
                      Recruitment
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq_en" title="faq">
                      Recruitment FAQ
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/contact_en" title="contact">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;

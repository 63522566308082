import React ,{ useState } from 'react'
import Footer from '../../components/ko/Footer'
import Header from '../../components/ko/Header'
import { Link } from 'react-router-dom';
import ContactModal from '../../components/ko/ContactModal';


const Introduce = () => {
  const [isOpenContact, setIsOpenContact] = useState(false);
  const onclickModal = () =>{
    setIsOpenContact(true)
  }
  return (
    <div className="wrap">
      <Header />

      <div id="content">
        <section id="introMain" className="sub_main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">ABOUT METAROCK</p>
                <h3 className="wow fadeInDown" data-wow-delay="0.4s">메타버스와 E-스포츠를 통한<br />새로운 세상과의 만남.</h3>
              </div>
            </div>
          </div>
        </section>

        <section id="intro">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="0.2s">
              

                <h4>Metarock은 기존의 플랫폼중심에서 벗어난 <br className="pc" />완벽한 탈중앙 메타버스 네트워크 서비스를 제공합니다.</h4>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInRight" data-wow-delay="0.4s">
                <p>당사는 메타버스를 구현하는 각 구성요소들을 개발 및 제휴, 협력을 통해 구현하고자 설립되었습니다. 게임, VR, AI, Entertainment, E-Commerce 등을 망라하여 오랫동안 기술개발에 매진한 전문가들로 구성되어 있으며, NFT, P2E게임, 실감형 컨텐츠 기반 비즈니스 등 장르에 제한 없이 당사의 메타버스 네트워크서비스 DotCube Protocol를 통해 자신만의 메타버스 플랫폼을 구성할 수 있습니다.

                </p>
                <div className="ceo wow fadeInRight" data-wow-delay="0.6s">주식회사 메타록 <span>대표이사 조영욱</span></div>
              </div>
            </div>
          </div>
        </section>

        <section id="business" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">METAROCK의 비즈니스</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">당신을 위한 서비스</h4>
                <p className="text wow fadeInDown" data-wow-delay="0.6s">게임 위의 게임. 플랫폼을 만드는 플랫폼을 경험해보세요.</p>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                <div className="box">
                  <Link to="/game" title="투윈챈스">
                    <div className="thumb">

                      <img src={`${process.env.PUBLIC_URL}/images/business/business1.png`} alt="2WinChance" />
                    </div>
                    <div className="cont">
                      <h5>2WinChance</h5>
                      <p>더 알아보기<i className="far fa-chevron-right"></i></p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0.9s">
                <div className="box">
                  <Link to="/metaverse" title="닷큐브프로토콜">
                    <div className="thumb">
                      <img src={`${process.env.PUBLIC_URL}/images/business/business2.png`} alt="메타버스" />
                    </div>
                    <div className="cont">
                      <h5>DotCube Protocol</h5>
                      <p>더 알아보기<i className="far fa-chevron-right"></i></p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.0s">
                <div className="box">
                  <Link to="/heaven" title="헤븐">
                    <div className="thumb">
                      <img src={`${process.env.PUBLIC_URL}/images/business/business3.png`} alt="Heaven" />
                    </div>
                    <div className="cont">
                      <h5>Heaven</h5>
                      <p>더 알아보기<i className="far fa-chevron-right"></i></p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="1.1s">
                <div className="box">
                  <Link to="/mozza" title="모짜와살리">
                    <div className="thumb">
                      <img src={`${process.env.PUBLIC_URL}/images/business/business4.png`} alt="Mozza & Sali" />
                    </div>
                    <div className="cont">
                      <h5>Mozza &amp; Sali</h5>
                      <p>더 알아보기<i className="far fa-chevron-right"></i></p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="organization" className="sub_cont">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="title wow fadeInDown" data-wow-delay="0.2s">Organization Chart</p>
                <h4 className="wow fadeInDown" data-wow-delay="0.4s">메타록 조직도</h4>
                <img src={`${process.env.PUBLIC_URL}/images/organization/organization.png?ver=20221207`} alt="메타록 조직도" className="pc_img wow fadeInUp"
                  data-wow-delay="0.8s" />
                <img src={`${process.env.PUBLIC_URL}/images/organization/organization_m.png?ver=20221207`} alt="메타록 조직도" className="m_img wow fadeInUp"
                  data-wow-delay="0.8s" />
              </div>
            </div>
          </div>
        </section>

        <section id="introContact" className="sub_contact">
          <div className="container">
            <div className="row">
              <h4 className="wow fadeInDown" data-wow-delay="0.4s">메타록에 문의사항이 있으신가요?</h4>
              {/* <button
                title="contact" 
                className="btn_contact wow fadeInUp"
                data-wow-delay="0.8s"
                onClick={() => {
                  onclickModal()
                }}
              >
                CONTACT US
              </button> */}
                <Link to={"/contact"} className="btn_contact wow fadeInUp" data-wow-delay="0.8s">CONTACT US</Link>
              
            </div>
          </div>
        </section>
      </div>
      {isOpenContact ? (
              <ContactModal setIsOpenContact={setIsOpenContact} />
      ) : null}

      <footer>
        <div className="container">
          <Footer />
          
        </div>
      </footer>
    </div>
  )
}

export default Introduce